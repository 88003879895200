.drawer_paper {
  background-color: #f8f8f8 !important;
  border-right: 0 !important;
  z-index: 1 !important;
}
@media print {
  .drawer_paper {
    display: none !important;
  }
}
@media print {
  .drawer_root {
    display: none !important;
  }
}
@media print {
  .hidePrint {
    display: none !important;
  }
}
