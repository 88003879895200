.Icon {
  cursor: pointer;
}
.text {
  color: #6f7981;
  font-size: 12px;
  max-width: 196px;
  display: inline-block;
  line-height: 1.3;
  margin-bottom: 6px;
}
.Dropdown > div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}
