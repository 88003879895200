.userTooltip {
  left: -115px !important;
}
.grpFilterTooltip {
  display: flex;
  align-items: center;
}
.userTooltip i {
  left: 146px !important;
}
.userExitButton {
  position: relative;
  top: 1px;
}
.toggle_tooltips_filter_table {
  top: 120%;
  right: 0;
  width: 170px;
  font-weight: 400;
  position: absolute;
  z-index: 99;
  text-align: left;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-radius: 4px;
}
@media (max-width: 900px) {
  .toggle_tooltips_filter_table {
    left: -70px;
  }
}
.toggle_tooltips {
  top: 120%;
  left: 0;
  width: 160px;
  font-weight: 400;
  position: absolute;
  z-index: 99;
  text-align: left;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-radius: 4px;
}
@media (max-width: 900px) {
  .toggle_tooltips {
    left: -70px;
  }
}
.toggle_tooltips i {
  position: absolute;
  font-size: 15px;
  top: -6px;
  left: 35px;
  width: 12px;
  height: 24px;
  overflow: hidden;
}
@media (max-width: 900px) {
  .toggle_tooltips i {
    left: 105px;
  }
}
.toggle_tooltips_filter_table i {
  position: absolute;
  font-size: 15px;
  top: -6px;
  right: 35px;
  width: 12px;
  height: 24px;
  overflow: hidden;
}
@media (max-width: 900px) {
  .toggle_tooltips_filter_table i {
    left: 105px;
  }
}
.toggle_tooltips i:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 0;
  top: 10%;
  transform: rotate(45deg);
  background-color: #fff;
}
.toggle_tooltips_filter_table i:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 0;
  top: 10%;
  transform: rotate(45deg);
  background-color: #fff;
}
.toggle_tooltips ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}
.toggle_tooltips li {
  margin: 0;
  height: 48px;
  line-height: 48px;
  padding-left: 16px;
  color: #6f7981;
}
.toggle_tooltips li:hover {
  color: #fa820f;
}
.toggle_tooltips_filter_table ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}
.toggle_tooltips_filter_table li {
  margin: 0;
  height: 48px;
  line-height: 48px;
  padding-left: 16px;
  color: #6f7981;
}
.toggle_tooltips_filter_table li:hover {
  color: #fa820f;
}
.toggle_tooltips li:first-child {
  position: relative;
  top: 1px;
}
.toggle_tooltips li:nth-child(2) {
  position: relative;
  top: -1px;
}
.logout {
  display: flex;
  align-items: center;
  border-top: solid 1px #efefef;
}
.logout svg {
  margin-right: 7px;
}
.logout:hover svg path {
  fill: #fa820f;
}
@media screen and (min-width: 901px) {
  .blackMode {
    background: #000 !important;
  }
  .blackMode:hover {
    background: #222 !important;
  }
}
