.ProfileView {
  padding: 36px 24px;
  position: relative;
  display: flex;
}
.settings {
  position: absolute;
  right: 24px;
  top: 24px;
}
.left {
  flex: 0 0 263px;
  margin-right: 48px;
  min-height: 310px;
}
.right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.username {
  font-size: 24px;
  color: #383838;
  font-weight: 600;
}
.userrole {
  color: #6f7981;
  margin-top: 12px;
  margin-bottom: 30px;
}
.list {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1 0 auto;
}
.list li {
  margin-bottom: 20px;
  font-size: 14px;
}
.list li span {
  display: inline-block;
}
.list li span:first-child {
  width: 140px;
  margin-right: 12px;
}
.profileImage {
  width: 263px;
  height: 310px;
}
.wrapper {
  margin-bottom: 30px;
}
.container {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
.container > div {
  background: #fff;
  border-radius: 3px;
}
.container > div:first-child {
  flex: 1;
}
@media screen and (max-width: 1080px) {
  .container {
    flex-direction: column;
  }
}
