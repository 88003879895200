.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}
@media screen and (max-width: 900px) {
  .header {
    flex-wrap: wrap;
  }
}
.title {
  width: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-right: 15px;
}
.title.subtitleHas {
  padding-bottom: 4px;
}
@media screen and (max-width: 1600px) {
  .title {
    width: 250px;
  }
}
@media screen and (max-width: 900px) {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
  }
}
.subtitle {
  color: #383838;
  font-size: 14px;
  font-weight: 300;
}
.subtitle.leftPadding {
  padding-left: 50px;
}
.content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 auto;
}
.content > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content > div:not(:last-child) {
  flex: 1 0 auto;
}
.content > div > button {
  margin-right: 15px;
}
.content > div > button:last-child {
  margin-right: 0;
}
.header__filter {
  flex: 1;
  margin: 0 15px;
}
@media screen and (max-width: 900px) {
  .header__filter {
    margin: 15px 0;
  }
}
@media screen and (max-width: 900px) {
  .header__button {
    display: none;
  }
}
.header__button_phone {
  display: none;
}
@media screen and (max-width: 900px) {
  .header__button_phone {
    display: block;
  }
}
.arrow {
  height: 16px;
}
.arrow:before {
  top: -13px;
}
