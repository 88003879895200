.chips {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -8px;
  margin-top: 4px;
}
.value {
  white-space: nowrap;
  overflow: hidden;
}
