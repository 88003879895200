.header {
  display: flex;
  background-color: #5b6770;
}
.app_bar {
  background-color: #5b6770 !important;
  box-shadow: none !important;
}
.gutters {
  padding: 0;
}
.links {
  display: flex;
  align-items: center;
}
.link {
  display: block;
  text-decoration: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
}
.link.link_logo {
  margin-left: 8px;
  margin-right: 50px;
}
.link.link_space {
  margin: 0 30px;
}
.link.link_space:last-child {
  margin-left: 0;
}
.userArrow {
  top: 20px;
  right: 20px;
  position: absolute;
}
.header_dots:hover {
  background: #79828a !important;
}
.userArrow_mobile {
  top: 25px;
  right: 10px;
  position: absolute;
}
.userArrow_mobile svg {
  height: 16px !important;
  width: 16px !important;
  fill: #5b6770 !important;
}
.user_info {
  margin-left: auto;
  display: flex;
  align-items: center;
  height: 66px;
}
.user_info .user_period {
  color: #bcc1c5;
  font-size: 18px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
}
.user_info .user_period svg {
  margin-right: 12px;
}
.user_info .badge {
  background-color: #f0a92c;
  font-size: 12px;
  font-weight: 700;
  padding: 0 5px;
  height: 17px;
}
.user_info .user_button {
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  min-width: 60px;
  border-left: solid 1px #79828a;
  background-color: #57636b;
  cursor: pointer;
  user-select: none;
}
.user_info .user_button:hover {
  background-color: #79828a;
}
@media (max-width: 900px) {
  .user_info .user_button:hover {
    background-color: transparent;
  }
}
.user_info .user_button_mobile {
  background: transparent;
  width: 65px;
  border-left: 0;
}
.user_info .user_button__active {
  background-color: #717b84 !important;
}
.user_info .user_button__activeBlack {
  background-color: #222 !important;
}
.user_info .user_button__active_mobile {
  background-color: #717b8410 !important;
}
.user_info .user_button__active_mobileBlack {
  background-color: #222 !important;
}
.user_info .user_avatar {
  width: 42px;
  min-width: 42px;
  height: 42px;
  position: relative;
  margin-right: 13px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .user_info .user_avatar {
    overflow: visible;
  }
}
.user_info .user_avatar > svg {
  width: 42px;
  height: 42px;
}
.user_info .user_avatar__reAuthStatus {
  position: absolute;
  width: 12px;
  height: 12px;
  bottom: 0;
  right: -2px;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: help;
}
@keyframes statusAnimate {
  0% {
    transform: scale(0.8);
    opacity: 1;
  }
  100% {
    transform: scale(2.4);
    opacity: 1;
  }
}
.user_info .notifications {
  position: relative;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: flex-start;
}
.user_info .notifications_count {
  position: absolute;
  top: 13px;
  right: 6px;
  font-weight: bold;
  font-size: 12px;
  background-color: #ffaa14;
  opacity: 1;
  z-index: 9999;
  padding: 0 5px;
  display: flex;
  align-self: center;
  border-radius: 20px;
}
.user_info .notifications_count_mobile {
  color: #fff;
}
.user_info .user_chat {
  position: relative;
}
.user_info .user_profile {
  position: relative;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: flex-start;
  font-weight: 500;
}
.user_info .user_profile_mobile {
  position: relative;
  box-sizing: border-box;
  padding: 0;
  justify-content: center;
  width: 90px;
  font-weight: 500;
}
.user_info .user_name {
  color: #ffffff;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
}
.user_info .user_position {
  color: #aeb4b8;
  font-size: 13px;
  font-weight: 400;
}
.drawer_paper {
  background-color: #f8f8f8 !important;
  border-right: 0 !important;
  z-index: 1 !important;
}
.grid_wrapper {
  background-color: #eff0f1;
}
@media (min-width: 600px) {
  .gutters {
    padding-left: 20px !important;
    padding-right: 0 !important;
  }
  .regular {
    height: 66px;
  }
}
.footer {
  height: 78px;
  padding: 0 24px;
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  flex-basis: auto;
}
.item {
  height: 60px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}
.contentk {
  flex-wrap: nowrap !important;
  box-sizing: inherit !important;
}
.copyright {
  color: #b3b4b4;
}
.footer__mobile {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer_root {
  border: 1px solid #e0e0e0 !important;
  border-radius: 4px !important;
  background: #e5e5e5 !important;
}
.footer_button {
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize !important;
  transition: all 0.1s ease;
  height: 40px !important;
  border: none !important;
  padding: 2px 15px !important;
}
.footer_button:disabled {
  background: #e5e5e5 !important;
  font-weight: normal !important;
  color: #6f7981 !important;
  font-size: 15px !important;
  text-transform: none;
}
.footer_buttonActive {
  background: #eff0f1 !important;
  color: rgba(111, 121, 129, 0.5) !important;
}
.footer_buttonActive:hover {
  background: #e5e5e5 !important;
  color: #6f7981 !important;
}
.chatNotify {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #44b700;
  position: absolute;
  right: 0;
  top: 0;
}
.blackMode {
  background: #000 !important;
}
.blackMode:hover {
  background: #222 !important;
}
