.posRelative {
  position: relative;
  cursor: pointer;
  min-width: 200px;
  transition: height 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}
.statusFieldActive {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: relative;
}
.statusFieldActive .statusTitle {
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.statusTitleWrapper {
  background-color: #fff;
  border-radius: 5px 5px 0 0;
  height: 42px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}
.titleStatus {
  position: relative;
  top: 5px;
  color: #6f7981;
  font-size: 12px;
  font-weight: 400;
}
.statusTitleWrapperTransparent {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #e3e3e3;
  border-radius: 5px;
}
.statusTitleWrapperNotTransparent {
  background-color: #fff !important;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
}
.statusTitleWrapperDisabled {
  background-color: #f7f7f7 !important;
}
.statusTitleWrapperTransparentDisabled {
  background-color: transparent !important;
}
.statusTitleText {
  font-size: 14px;
  line-height: 21px;
  width: 130px;
  text-overflow: ellipsis;
  display: inline;
  white-space: nowrap;
  overflow: hidden;
}
.statusTitle {
  font-size: 15px;
  color: #2b2b2b;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.statusTitle span svg path {
  color: #707070;
  fill: #707070;
}
.statusList {
  transition: all 0.5s ease;
  background: white !important;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  color: #6f7981;
  font-size: 15px;
  font-weight: 400;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 20px 10px 15px 20px;
  z-index: 12;
  width: 150px;
  margin-top: 10px;
  min-width: 170px;
  min-height: 125px;
}
.statusList .item {
  padding-bottom: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
}
.statusList .item span {
  font-size: 13px;
}
.statusList .item:hover {
  color: #fa6900;
}
.statusList .item:last-child {
  padding: 0;
}
.statusList .iconUp {
  fill: #56be81;
}
.statusList .iconDown {
  fill: #e74c3c;
}
.statusListIn {
  transition: all 0.5s ease;
  background: white !important;
  position: absolute;
  color: #6f7981;
  font-size: 15px;
  font-weight: 400;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  padding: 15px 10px 15px 20px;
  z-index: 11;
  border-radius: 4px;
  margin-top: 10px;
  min-width: 260px;
  min-height: 200px;
}
.statusListIn > div {
  min-height: 180px !important;
  max-height: 200px !important;
}
.statusListIn > div > div:first-child {
  height: 100% !important;
  min-height: 180px !important;
  max-height: 200px !important;
  overflow-x: hidden !important;
  margin-bottom: 0 !important;
}
.statusListIn .item {
  padding-bottom: 10px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}
.statusListIn .item:hover {
  color: #fa6900;
}
.statusListIn .item:last-child {
  padding: 0;
}
.statusListIn .iconUp {
  fill: #56be81;
}
.statusListIn .iconDown {
  fill: #e74c3c;
}
.arrow {
  position: absolute !important;
  right: 8px;
  top: 8px;
  margin: 0;
}
.arrow span {
  margin: 0;
}
.selected {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialogWrapper {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  height: 175px;
}
.btnWrapper {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.btnWrapper button:first-child {
  margin-right: 15px;
}
.circleWrapper {
  align-self: flex-start;
  margin-top: 5px;
}
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
