.fieldItem {
  margin: 5px 0;
}
.fieldItemCronOnce {
  margin: 5px 0 25px 0;
}
.rolesBlockWrapAdditional {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.fieldItemInside {
  flex: 0 1 33%;
  padding-right: 20px;
  margin-bottom: 15px;
}
@media (max-width: 900px) {
  .fieldItemInside {
    flex: 0 1 100%;
  }
}
.fieldItemInsideSide {
  width: 100%;
}
.item_title {
  font-size: 13px;
  font-weight: 400;
  color: #6f7981;
  margin: 10px 0;
}
.AvatarDialogWrapper {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.AvatarDialogWrapper .UserPosition {
  border-radius: 3px;
  color: black;
  padding: 0 12px;
  font-weight: 500;
  line-height: 16px;
}
.AvatarDialogWrapper .UserPosition .title {
  font-size: 14px;
  letter-spacing: 0;
  color: #525252;
  font-weight: 500;
}
.AvatarDialogWrapper .UserPosition .subTitle {
  color: #bfbfbf !important;
  font-weight: 400;
}
.AvatarDialogWrapper .UserPosition span {
  font-weight: normal;
  color: #b3b3b3;
}
.suggestionWrapper {
  display: flex;
}
.suggestionWrapper .suggestion_avatar {
  margin-right: 10px;
}
.suggestionWrapper .suggestion_org {
  font-size: 12px;
  color: #707070;
  opacity: 0.57;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 900px) {
  .hidePhone {
    display: none;
  }
}
@media screen and (max-width: 1280px) {
  .hideIPad {
    display: none;
  }
}
.tableIcons {
  display: flex;
}
.tableIcons > span:first-child,
.tableIcons span:nth-child(2) {
  margin-right: 10px;
}
.changeStatusWrapper {
  position: relative;
  cursor: pointer;
  min-width: 200px;
  transition: height 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}
.routesCardWrapper .title {
  font-size: 16px;
  color: #333333;
}
.routesCardWrapper .desc {
  color: #6f7981;
  font-size: 13px;
}
.routeCardCount {
  color: #414548;
  font-size: 14px;
  font-weight: 400;
  margin-top: 30px;
}
.statusList {
  transition: all 0.1s ease;
  background: white !important;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  color: #6f7981;
  font-size: 15px;
  font-weight: 400;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 4px 4px;
  padding: 20px 10px 15px;
}
.statusList .item {
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.statusList .item:hover {
  color: #fa6900;
}
.statusList .item:last-child {
  padding: 0;
}
.statusList .iconUp {
  fill: #56be81;
}
.statusList .iconDown {
  fill: #e74c3c;
}
.statusField {
  border: 1px;
}
.statusField .statusTitle {
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.statusField .arrow {
  position: absolute !important;
  right: 5px;
  margin: 0;
}
.statusField .arrow span {
  margin: 0;
}
.dialogWrapper {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  height: 175px;
}
.dialogWrapper .btnWrapper {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.dialogWrapper .btnWrapper button:first-child {
  margin-right: 15px;
}
.posMainDealerMonth {
  position: relative;
  padding: 20px 0;
  left: 80px;
  border-bottom: 1px solid #e8e8e8;
}
.userAgentsAC {
  flex: 1;
  margin-left: 15px;
  background: #fff;
}
.userAgents__wrapper {
  margin-top: 20px;
}
.userAgents__loaderStack {
  color: #6f7981;
  font-size: 13px;
}
.posMainItem {
  height: 100%;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.posMainItem .posMainItem__quarter {
  font-size: 16px;
  margin-bottom: 10px;
}
.posMainItem .posMainItem__month {
  font-size: 16px;
}
.posMainItem .posMainItem__graph {
  padding: 5px;
}
.posMainItem .posMainItem__buttons {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.posMainItem .posMainItem__button {
  width: 100%;
  margin-top: 10px;
}
.tableRowDataNotFound {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropDownStatusWrapper {
  position: relative;
  display: flex;
}
.dropDownStatusWrapper span {
  flex-basis: 95%;
}
.layoutWrapper {
  height: 100%;
  min-height: 100%;
  width: 100%;
}
.layoutWrapper .layoutHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 22px 0;
}
.layoutWrapper .layoutBody {
  height: 100%;
}
.layoutWrapper .layoutBody__graph {
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.layoutWrapper .layoutBody__graph .layoutHeader {
  background: #eff0f1;
}
.layoutWrapper .layoutBody__graph .graph__playgroundWrapper {
  width: 100%;
  min-width: 1px;
  position: relative;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
}
.layoutWrapper .layoutBody__graph .graph__playgroundWrapper .graph__playgroundInEditingStage {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: #00000040;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #fff !important;
}
.layoutWrapper .layoutBody__graph .graph__sideBar {
  margin-left: 15px;
  margin-top: 25px;
  width: 300px;
}
.layoutWrapper .layoutBody__pos {
  display: flex;
  height: 100%;
  flex-wrap: wrap;
}
.layoutWrapper .layoutBody__pos .layoutBody__posItem {
  min-height: 0;
  min-width: 0;
  width: 16%;
  flex-basis: 16%;
  margin-bottom: 10px;
  margin-right: 10px;
}
@media (max-width: 1640px) {
  .layoutWrapper .layoutBody__pos .layoutBody__posItem {
    width: 24%;
    flex-basis: 24%;
  }
}
@media (max-width: 1280px) {
  .layoutWrapper .layoutBody__pos .layoutBody__posItem {
    width: 32%;
    flex-basis: 32%;
  }
}
@media (max-width: 900px) {
  .layoutWrapper .layoutBody__pos .layoutBody__posItem {
    width: 48.5%;
    flex-basis: 48.5%;
  }
}
@media (max-width: 700px) {
  .layoutWrapper .layoutBody__pos .layoutBody__posItem {
    width: 100%;
    flex-basis: 100%;
    margin-right: 0;
  }
}
h1 {
  color: #585858;
  font-size: 23px;
  font-weight: 300;
  padding-left: 13px;
  line-height: 24px;
}
h2 {
  color: #383838;
  font-size: 20px;
  font-weight: 300;
}
h3 {
  color: #373a3c;
  font-size: 18px;
  font-weight: 500;
}
h4 {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.arrowHeader {
  width: 16px !important;
}
.rowHover:hover {
  background-color: #fbfbfc !important;
  cursor: pointer;
}
.arrowInput {
  position: absolute;
  right: 15px;
  pointer-events: none;
  top: 15px;
}
.tableHeadersBorder {
  color: #6f7981;
  border-bottom: 2px solid #e8e8e8 !important;
  border-radius: 4px;
}
.tableRowDataNotFound__inside {
  color: #aeb4b8;
  font-size: 16px;
}
.tableMobileBodyItem {
  display: flex;
  flex-direction: column;
  padding: 10px 18px;
  font-size: 15px;
  cursor: pointer;
}
.tableMobileBodyItem:hover {
  background: #fbfbfc;
}
.tableMobileBodyItem:not(:first-child) {
  border-top: 1px solid #e8e8e8;
}
.tableMobileBodyItem__svg {
  margin-right: 5px;
}
.tableMobileBodyItem__line {
  display: flex;
  padding-bottom: 5px;
  align-items: center;
}
.tableMobileBodyItem__title {
  color: #35393c;
}
.tableMobileBodyItem__cost {
  color: #35393c;
  font-weight: bold;
  flex-grow: 1;
  text-align: right;
}
.tableMobileBodyItem__activity {
  color: #35393c;
  padding-bottom: 5px;
}
.tableMobileBodyItem__status_and_date {
  color: #35393c;
  padding-bottom: 5px;
}
.tableMobileBodyItem__status_and_date span {
  font-size: 13px;
  color: #5b6770;
}
.tableMobileBodyItem__id {
  color: #35393c47;
}
.trackHorizontal {
  height: 100%;
  min-height: 100%;
  z-index: 9;
  top: 0;
  right: 2px;
}
.thumbHorizontal {
  cursor: pointer;
  border-radius: 3px;
  z-index: 12;
  background-color: #d4d7da;
  transform: translateX(0px);
}
.tasksCloud,
.tasksCloudQuery {
  position: relative;
  min-height: 360px;
  height: 100%;
}
.tasksCloud::before,
.tasksCloudQuery::before {
  position: absolute;
  content: "";
  pointer-events: none;
  width: 100%;
  height: 30px;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  bottom: 15px;
  left: 0;
  z-index: 1;
  border-radius: 0 0 4px 4px;
}
.tasksCloudQuery::before {
  content: "";
  bottom: 0;
}
.svgGray {
  fill: #5b6770 !important;
}
.svgWhite {
  fill: #fff;
}
@media (max-width: 1449px) {
  .statusChangeRoot {
    display: flex !important;
  }
}
.tableMobileActivityLeftBlock label {
  margin-left: 11px !important;
  margin-right: 0 !important;
}
.containerCatalogTables {
  width: 100%;
}
.rootMobileRow {
  border-bottom: 1px solid #e8e8e8;
}
.pageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.pageHeaderWrap {
  flex-wrap: wrap;
}
.pageHeader__title {
  margin-right: 30px;
}
.pageHeader__filter {
  flex-grow: 1;
  margin-right: 10px;
}
@media screen and (max-width: 900px) {
  .pageHeader__filter {
    display: none;
  }
  .pageHeader__filter * {
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .pageHeader__filterWrap {
    order: 3;
    flex: 1 0 100%;
    padding-left: 4px;
    padding-right: 4px;
    margin-top: 15px;
  }
}
.pageHeader__filterMobile {
  margin-top: 10px;
  width: 100%;
  margin-right: 5px;
  margin-bottom: 10px;
}
@media screen and (min-width: 900px) {
  .pageHeader__filterMobile {
    display: none;
  }
  .pageHeader__filterMobile * {
    display: none;
  }
}
.defaultBlockWithLinkInside {
  margin: 0;
}
.defaultBlockWithLinkInside a {
  color: #4f95e8;
  cursor: pointer;
  text-decoration: none;
  display: inline;
}
.defaultBlockWithLinkInside a:hover {
  color: #fa820f;
}
.defaultLink {
  color: #4f95e8;
  cursor: pointer;
  text-decoration: none;
}
.defaultLink:hover {
  color: #fa820f;
}
.globalTooltipHint {
  background: #868d93 !important;
  padding: 15px !important;
  font-size: 14px !important;
}
.globalTooltipWiki {
  padding: 15px !important;
  font-size: 14px !important;
  background-color: #fff !important;
  color: #333 !important;
  box-shadow: 0 0 3px #d3d3d3 !important;
}
.wikiIcon {
  width: 18px !important;
  fill: #57636b !important;
  position: relative;
  top: 1px;
  left: 5px;
  cursor: pointer;
}
.wikiIcon:hover {
  opacity: 0.85;
}
.globalTooltipWikiArrow {
  color: #fff !important;
}
.globalTooltipHintArrow {
  color: #868d93 !important;
  bottom: 10px;
  left: 15px !important;
}
.globalTooltipHintPopper {
  z-index: 1400 !important;
}
.pageHeader__button {
  align-items: center;
  margin-right: 8px;
}
.pageHeader__button svg {
  cursor: pointer;
  width: 20px;
}
.pageHeader__button svg:hover {
  opacity: 0.9;
}
@media screen and (max-width: 900px) {
  .hidePhone {
    display: none !important;
  }
}
.tableMobileInfoBlock {
  display: flex;
  width: 100%;
}
.tableMobileInfoBlock__wrapper {
  flex-grow: 1;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  color: #35393c;
}
.tableMobileInfoBlock__info {
  flex-grow: 1;
  font-size: 15px;
  justify-content: flex-start;
  color: #35393c;
}
.tableMobileInfoBlock__line {
  padding-left: 18px;
}
.tableMobileInfoBlock__line:first-child {
  padding-top: 15px;
}
.tableMobileInfoBlock__line:last-child {
  padding-bottom: 15px;
}
.sizeSmallForTable {
  padding: 23px 16px !important;
}
.extraPaddingForTable td {
  padding: 19px 16px !important;
}
.extraPaddingForTableCap td:not(:last-child) {
  padding: 19px 16px !important;
}
.extraPaddingForTableCap td:last-child {
  padding: 0 3px 0 0 !important;
}
.toggleRolesWrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.toggleRolesWrap .role {
  margin-left: 15px;
  color: #6f7981;
  border-bottom: 1px dashed #6f7981;
  cursor: pointer;
}
.toggleRolesWrap .role.toggled {
  color: rgba(111, 121, 129, 0.5) !important;
  border-bottom: 1px dashed rgba(111, 121, 129, 0.5) !important;
}
.buttonInDialogs {
  margin-bottom: 20px;
}
.buttonInDialogs textarea {
  max-height: 100px;
}
.popupWrapper {
  max-width: 580px;
  margin: 0 auto;
  width: 100%;
  min-height: 350px;
  height: 100%;
  z-index: 13 !important;
  position: relative;
  overflow-y: visible !important;
}
.tableMobileInfoBlock__status {
  margin-bottom: 3px;
}
.tableMobileInfoBlock__status span {
  color: #5b6770;
  font-size: 13px;
}
.tableMobileInfoBlock__id {
  color: #35393c55 !important;
}
.tableLastHeaderNoPadding {
  padding: 0 !important;
  width: 2px !important;
}
.tableMobileInfoBlock__cost {
  font-weight: bold;
  margin-right: 20px;
  font-size: 15px;
}
.tableRowDeleting td {
  position: relative;
  background: white;
  z-index: 101;
}
.tableRowDeleting td:first-child {
  z-index: 102;
}
.tableRowGlobal td {
  border-bottom: 1px solid #e8e8e8;
}
.userAvatarLine {
  display: flex;
  align-items: center;
}
.userAvatarLine img {
  margin-right: 8px;
}
.dealerTableRow {
  padding: 18px;
  display: flex;
  font-size: 16px;
}
.dealerTableRow__spoiler_button {
  height: 40px;
  width: 40px;
  margin-left: -2px;
}
.dealerTableRow__right_info {
  margin-top: 5px;
}
.dealerTableRow__right_spoiler {
  margin-left: 5px;
  margin-top: -3px;
}
.dealerTableRow__spoiler {
  margin-top: 10px;
}
.dealerTableRow__title {
  color: #6f7981;
  font-size: 13px;
  text-transform: uppercase;
}
@media print {
  .footer {
    display: none;
  }
}
.footerCopyright {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}
.footerCopyright p {
  position: relative;
  left: -170px;
}
@media (max-width: 1280px) {
  .footerCopyright p {
    left: -50px;
  }
}
@media print {
  .footerCopyright {
    display: none;
  }
}
.posIcon {
  width: 48px;
  height: 48px;
}
.posIcon .cls-1 {
  fill: #e7e9ea;
}
.posIcon .cls-2 {
  fill: none;
  stroke: #54616c;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.3px;
}
.noData {
  min-height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.noData span {
  color: #bfbfbf;
  font-size: 16px;
}
