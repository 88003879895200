.header {
  margin-top: 0;
  margin-bottom: 0;
}
.header > div:first-child {
  width: 100%;
}
.form {
  display: flex;
  flex-wrap: wrap;
}
.form > div {
  flex: 0 0 33.33333333%;
  padding-right: 15px;
  margin-bottom: 15px;
}
@media screen and (min-width: 901px) {
  .form > div:nth-child(3n) {
    padding-right: 0;
  }
}
@media screen and (max-width: 900px) {
  .form > div {
    flex: 0 0 50%;
    padding-right: 15px;
  }
  .form > div:nth-child(2n) {
    padding-right: 0;
  }
}
@media screen and (max-width: 575px) {
  .form > div {
    flex: 0 0 100%;
    padding-right: 0 !important;
  }
}
.footer button {
  margin-right: 15px;
}
.footer button svg {
  margin-right: 6px;
}
.actions {
  display: flex;
}
.actions > button:first-child {
  margin-right: 10px;
}
.submitBtn path,
.submitBtn:hover path {
  fill: #27ae60 !important;
}
