.FileInfo {
  display: flex;
  align-items: center;
  gap: 12px;
}
.Icon {
  flex: 0 0 32px;
}
.Icon svg {
  height: 32px;
  width: 32px;
}
.Name {
  flex: 1 0 auto;
  font-size: 15px;
}
.FileLoading {
  margin-top: 12px;
  background: #ebebeb;
  height: 3px;
  width: 100%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}
.loading {
  background: #e67a2b;
  height: 3px;
}
.success {
  background: #3cc400;
}
.error {
  background: #e74c3c;
}
