.chain path {
  fill: #98a7b5;
}
.closeIcon {
  width: 20px;
  height: 20px;
  top: 14px;
  right: 35px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.closeIcon svg {
  width: 12px;
  height: 10px;
  z-index: 11;
  stroke: #6f6f6f !important;
}
.closeIcon:hover {
  background-color: #eee;
  border-radius: 50%;
}
