.collapse {
  min-height: calc(100vh - 214px);
  position: sticky;
  top: 66px;
  background: #fff;
  border-radius: 4px;
  width: 295px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
