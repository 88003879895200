.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header > h4 {
  font-weight: 300;
  font-size: 23px;
}
.info {
  display: flex;
  gap: 40px;
}
.infoItem {
  display: flex;
  align-items: center;
  gap: 8px;
}
.infoItem > svg {
  width: 20px;
  height: 20px;
}
.achievementsContainer {
  gap: 24px;
  display: flex;
  flex-wrap: wrap;
}
.achievementsContainer > div {
  flex: calc(50% - 24px);
}
