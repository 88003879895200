/* breakpoints */
.pagination {
  background-color: #fff;
  border-top: 2px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  min-height: 75px;
  color: #707070;
  font-size: 12px;
  margin-top: -1px;
}
.count {
  display: flex;
  align-items: center;
}
.count > div {
  margin-left: 10px;
}
.list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  color: #4f95e8;
  font-size: 15px;
  font-weight: 400;
}
.list button {
  font-weight: 400;
}
.list button span {
  width: 100%;
}
.list button:first-child {
  margin-right: 16px;
}
.list button:last-child {
  margin-left: 16px;
}
.list .pages {
  display: flex;
  align-items: center;
  justify-content: center;
}
.list .cell {
  padding: 0 7px;
  cursor: pointer;
}
.list .cell:hover {
  color: #fa820f;
}
.list .cellSelect {
  padding: 0 7px;
  color: #707070;
}
@media screen and (max-width: 768px) {
  .pagination {
    flex-wrap: wrap;
  }
  .total {
    order: 1;
  }
  .count {
    order: 2;
  }
  .list {
    padding: 0;
    margin-bottom: 10px;
    order: 0;
    flex: 0 0 100%;
  }
  .list button:first-child {
    margin-right: 8px;
    width: 50%;
  }
  .list button:last-child {
    margin-left: 8px;
    width: 50%;
  }
  .list .pages {
    display: none;
  }
}
