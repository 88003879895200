.btn > div {
  justify-content: center;
}
.sz-normal {
  padding-left: 0;
  padding-right: 0;
  height: 48px;
  width: 48px;
  flex: 0 0 48px;
}
.sz-small {
  height: 40px;
  width: 40px;
  flex: 0 0 40px;
  padding-left: 0;
  padding-right: 0;
}
.loader {
  margin-right: 0;
  margin-left: 0;
}
