.birthdayTitle {
  border-radius: 4px 4px 0 0;
  background-color: #ffaa14;
  height: 51px;
  display: flex;
  align-items: center;
  padding: 0 18px;
}
.birthdayTitle > div {
  color: #fff !important;
}
.birthdayTitle .caption {
  color: #ffffff !important;
  font-weight: 300;
}
.birthdayBody {
  padding: 18px 18px 12px;
}
@media screen and (max-width: 1280px) {
  .birthdayBody {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .birthdayBody .user {
    flex: 1 33%;
  }
}
@media screen and (max-width: 600px) {
  .birthdayBody {
    display: block;
  }
}
.birthdayBody .noBirthdays {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.birthdayBody .noBirthdays span {
  color: #bfbfbf;
  font-size: 16px;
}
.birthdayBody .user {
  margin-bottom: 18px;
  cursor: pointer;
}
.birthdayBody .user:hover .name {
  color: #f0a92c;
}
.birthdayBody .avatar {
  margin-right: 15px;
  height: 60px;
  float: left;
}
.birthdayBody .avatar div {
  height: 40px;
  width: 40px;
}
.birthdayBody .name {
  color: #383838;
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.birthdayBody .date {
  color: #202122;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}
.birthdayBody .company {
  color: #6f7981;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table .tableRoot thead > tr {
  border-bottom: 2px solid #e8e8e8;
}
.table .tableRoot td {
  padding: 9px 18px !important;
  font-size: 16px;
}
.table .tableRoot td:last-child {
  padding: 0 !important;
  width: 4px;
}
@media screen and (max-width: 1600px) {
  .table .tableRoot td {
    font-size: 15px;
  }
}
.table th {
  color: #808080;
  padding: 13px 16px 16px 16px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
}
.table td {
  cursor: pointer;
}
.table .title {
  padding: 20px 18px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table .link {
  color: #4f95e8;
  font-size: 12px;
  font-weight: 500;
  border-bottom: solid 1px #bed8f7;
  text-decoration: none;
  white-space: nowrap;
}
.table .link:hover {
  color: #fa820f;
  border-bottom: solid 1px #fa820f;
}
.table .subTitle {
  color: #9b9e9f;
  font-size: 13px;
  font-weight: 400;
}
.table .user {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.table .user .avatar {
  margin-right: 12px;
  width: 33px;
  height: 33px;
}
.table .user .avatar div,
.table .user .avatar img,
.table .user .avatar svg {
  width: 33px;
  height: 33px;
}
.table .user .avatar__mobile div,
.table .user .avatar__mobile img,
.table .user .avatar__mobile svg {
  width: 28px;
  height: 28px;
}
.table .data_not_found {
  display: flex;
  min-height: 200px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  flex-direction: column;
  color: #bfbfbf;
}
.table .data_not_found img {
  display: block;
  margin-bottom: 10px;
}
.table .data_not_found span {
  text-align: center;
}
.tableRowHover:hover {
  background-color: #fbfbfc !important;
}
.tableRowHover td {
  height: 60px;
  border-bottom: 1px solid #e8e8e8;
}
.status .statusCircle {
  align-self: flex-start;
  margin-top: 6px;
}
.status .title {
  padding: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
}
.status .link {
  color: #4f95e8;
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
}
.status .link:hover {
  color: #fa820f;
}
.status .event {
  color: #35393c;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 10px;
}
.status .time {
  color: #98a1a8;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 27px;
}
.status .content {
  margin: 0 5px 18px 18px;
  height: 355px;
  position: relative;
}
.status .data_not_found {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  flex-direction: column;
  color: #bfbfbf;
}
.status .data_not_found img {
  display: block;
  margin-bottom: 10px;
}
.status .data_not_found span {
  text-align: center;
}
.statusLog {
  padding-right: 8px;
}
.statusLog a {
  display: inline !important;
}
.statusLog .dealer {
  color: #282b2e;
  font-weight: bold;
}
.statusLog .link {
  color: #4f95e8;
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
}
.statusLog .link:hover {
  color: #fa820f;
}
.status .statusTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.status .subTitle {
  padding-left: 15px;
}
.circleWrap {
  padding-top: 6px;
  align-self: flex-start;
}
