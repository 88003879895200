.pos_relative {
  position: relative;
  display: flex;
  margin-top: 1px;
  margin-bottom: 1px;
}
.pos_relative button {
  transition: all 0.25s ease;
}
.pos_relative button:active line {
  opacity: 1;
}
.pos_relative button:hover {
  transform: scale(1.1);
}
.pos_relative .noTransition:hover {
  transform: none;
}
.pos_relative .noTransition line {
  opacity: 1;
}
.pos_relative .noTransition:active,
.pos_relative .noTransition:hover {
  transform: none;
  height: 12px;
}
.pos_relative .noTransition:before,
.pos_relative .noTransition:after,
.pos_relative .noTransition span {
  background: #6f7981;
}
.pos_relative svg line {
  fill: #6f7981;
  stroke: #6f7981;
  opacity: 0.6;
}
.pos_relative svg:hover line {
  opacity: 1;
}
.toggle {
  display: flex;
  align-items: center;
  color: #4f95e8;
  font-size: 15px;
  width: 16px;
  height: 12px;
  position: relative;
  cursor: pointer;
}
.toggle a {
  color: #4f95e8;
  text-decoration: none;
}
.toggle a:hover,
.toggle a:active {
  color: blue;
}
.tooltipsUnvisible {
  display: none;
  visibility: hidden;
}
.toggle_tooltips {
  position: absolute;
  z-index: 10;
  text-align: left;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #ffffff !important;
  left: 187%;
  top: -10px;
  border-radius: 4px;
}
@media (max-width: 900px) {
  .toggle_tooltips {
    left: 120%;
  }
}
.toggle_tooltips i {
  position: absolute;
  font-size: 15px;
  top: 8px;
  right: 100%;
  width: 12px;
  height: 24px;
  overflow: hidden;
}
.toggle_tooltips i:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 0;
  top: 45%;
  transform: translate(50%, -50%) rotate(-45deg);
  background-color: #fff !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}
.toggle_tooltips ul {
  list-style-type: none;
  padding: 0;
  margin: 20px;
  width: 133px;
}
.toggle_tooltips li {
  margin: 0 0 10px;
  color: #4f95e8;
  cursor: pointer;
}
.toggle_tooltips li a {
  color: #4f95e8;
}
.toggle_tooltips li:hover,
.toggle_tooltips li:active {
  color: #fa820f;
}
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
}
