.actionIcon {
  border-radius: 50%;
}
.button {
  cursor: pointer;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #ebebeb;
}
.button:hover {
  background: #f7f7f7;
}
.contextMenu {
  border: 1px solid #ebebeb;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px rgba(84, 97, 108, 0.17);
  position: absolute;
  min-width: 167px;
  padding: 16px;
  z-index: 11;
}
.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.item {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.item .itemIcon {
  flex: 0 0 24px;
}
.item .itemTitle {
  font-size: 14px;
  color: #66727d;
}
.item:hover .itemTitle {
  color: #26323d;
}
.item:not(:last-child) {
  margin-bottom: 8px;
}
.DropdownOpened .actionIcon {
  background-color: var(--color-bg);
}
