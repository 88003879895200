.answer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  color: #383838 !important;
  font-size: 14px;
}
.answer .wrap {
  border-left: solid 5px #88b2d6;
  padding-left: 5px;
}
.answer .userName {
  font-weight: 500;
}
.answer .text {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.answer .close {
  cursor: pointer;
  opacity: 0.4;
}
.answer .close line {
  stroke: #383838;
}
.answer .close:hover {
  opacity: 1;
}
