.container > label {
  padding-right: 10px;
  pointer-events: none;
}
.container > label > span {
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 0;
}
