.achievementsShowcaseMain {
  width: 100%;
  height: auto;
  max-width: 381px;
  background: #fff;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1080px) {
  .achievementsShowcaseMain {
    max-width: 100%;
  }
}
.mainWrapper {
  padding: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.achievementsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.achievementsHeader > h5 {
  font-size: 20px;
  font-weight: 500;
}
.achievementsHeaderInfo {
  display: flex;
  gap: 16px;
}
.achievementsHeaderInfo > span {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
}
.achievementsItemWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.achievementsItemWrapper > p {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin: 0;
}
.achievementsList {
  display: flex;
  flex-wrap: wrap;
  gap: 11px;
  max-height: 290px;
  overflow-y: auto;
}
.achievementsList > picture {
  width: calc(33.33333% - 11px);
}
@media screen and (max-width: 575px) {
  .achievementsList > picture {
    width: calc(50% - 11px);
  }
}
.fullHeight {
  max-height: 466px;
}
.achievementsList::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}
.achievementsList::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
.achievementsList::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
  background-color: #d4d7da;
}
.achievementsPreviewContainer {
  box-sizing: border-box;
  padding: 20px;
  width: 100px;
  height: 100px;
  position: relative;
  background: #f6f7f9;
  border-radius: 8px;
  border: 2px solid transparent;
  transition: all 0.1s linear;
}
.achievementsPreviewContainer > img {
  height: 100%;
}
.achievementsCounter {
  position: absolute;
  right: 4px;
  top: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 23px;
  min-height: 23px;
  background: #edeff2;
}
.accessButton {
  cursor: pointer;
  border-top: 1px solid #eff0f1;
  color: #363839;
  padding: 34px;
  background: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  transition: all 0.1s ease-in;
}
.accessButton:hover {
  color: #eff0f1;
  background: #363839;
  border-top: 1px solid #363839;
}
.accessButton:hover > svg > path {
  fill: #fff;
}
.activeBtn {
  color: #eff0f1;
  background: #363839;
  border-top: 1px solid #363839;
}
.largeCounter {
  width: 35px;
  border-radius: 8px;
}
.pointer {
  cursor: pointer;
}
.markAsPublic {
  border-color: #ffaa14;
}
