.select-wrapper {
  position: relative;
  cursor: pointer;
  text-align: left;
  background-color: #fff;
}
.select-wrapper .select-container {
  border: 1px solid #efefef;
  border-radius: 4px;
  width: 100%;
  height: 49px;
  display: flex;
  padding-left: 15px;
  padding-right: 12px;
  align-items: center;
}
.select-wrapper .select-container:hover,
.select-wrapper .select-container:focus {
  border: 1px solid #9fcafd;
}
.select-wrapper .select-container .title {
  position: absolute;
  color: #6f7981;
  font-size: 16px;
  transform-origin: left top;
  transition: 0.3s;
  overflow: hidden;
  white-space: nowrap;
  width: 80%;
}
.select-wrapper .select-container .arrow {
  position: absolute;
  right: 15px;
  top: 12px;
  color: #6f7981;
}
.select-wrapper .select-container .arrow svg {
  transform: rotate(180deg);
  transform-origin: center;
  transition: 0.3s;
}
.select-wrapper .select-container .value {
  display: flex;
  opacity: 0;
  margin-top: 6px;
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
  width: 80%;
}
.select-wrapper .select-container .value .placeholder {
  color: #6f7981;
}
.select-wrapper .options {
  position: absolute;
  z-index: 13;
  background-color: #ffffff;
  top: 100%;
  margin-top: 5px;
  width: 100%;
  border: 1px solid #efefef;
  padding: 6px 0;
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  color: #6f7981;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: 400px;
}
.select-wrapper .options .option {
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 12px 8px 12px;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 4px;
}
.select-wrapper .options .option:hover {
  background: #f1f3f6;
}
.select-wrapper.focused .select-container {
  border-color: #9fcafd;
}
.notEmpty .title {
  transform: translate(-1px, -11px) scale(0.75);
  transition: 0.3s;
}
.notEmpty .value {
  opacity: 1 !important;
}
.disabled {
  background: #eef0f1;
  cursor: default;
}
.disabled:hover,
.disabled:focus {
  border: 1px solid #efefef !important;
}
.focused .arrow svg {
  transform: rotate(0deg) !important;
}
.required {
  color: red;
}
.disabledOption {
  cursor: default !important;
  color: #cacad3 !important;
  margin-left: -7px;
}
.selected {
  color: #2f3941;
  background: #f1f3f6;
}
.error {
  margin-bottom: 10px;
}
.searchInput {
  width: 80%;
  border: none;
}
