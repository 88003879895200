.taskManagerDropField {
  background: #fbfbfb;
  width: 100%;
  height: 220px;
  display: flex;
  border: 1px solid #efefef;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 15px;
}
.taskManagerDropField .active {
  border-color: #4f95e8 !important;
}
.taskManagerDropField .taskManagerDropField_InsideBorder {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #d5d5d5;
  height: 100%;
  width: 100%;
}
.taskManagerDropField .taskManagerDropField_content {
  display: flex;
  flex-direction: column;
  font-weight: 300;
  font-size: 16px;
  color: #505050;
  align-items: center;
  justify-content: center;
}
.taskManagerDropField .taskManagerDropField_content svg {
  margin-bottom: 5px;
}
.category {
  font-size: 13px;
  color: #a7a7a7;
}
.randomIcon {
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  width: 45px;
  height: 46px !important;
  border-left: 1px solid #efefef;
}
.randomIcon svg {
  position: absolute;
  top: 15px;
  left: 15px;
}
.arrowPreview {
  top: 50%;
  transform: translate(0, -50%);
  position: absolute;
  cursor: pointer;
}
.arrowPreview svg {
  font-size: 32px !important;
  color: #fff !important;
}
@media (max-width: 900px) {
  .arrowPreview svg {
    color: #fa820f !important;
  }
}
.arrowPreview:hover svg {
  color: #fa820f !important;
}
@keyframes loader {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tools {
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  padding: 16px;
}
.tools .utilities {
  display: flex;
  flex-wrap: wrap;
}
.tools .utilities:not(:last-child) button,
.tools .utilities:not(:last-child) a {
  margin-bottom: 8px;
}
.tools button:not(:last-child),
.tools a:not(:last-child) {
  margin-right: 8px;
}
.closeBtn {
  position: absolute;
  left: inherit;
  right: -10px;
  bottom: 100%;
  background: transparent !important;
  color: #fff !important;
  border: 0 !important;
  margin-right: 0 !important;
}
.closeBtn svg {
  transition: all 0.3s;
}
.closeBtn:hover {
  color: rgba(255, 255, 255, 0.8) !important;
}
@media screen and (max-width: var(--screen-xs)) {
  .closeBtn {
    left: inherit;
    right: 0;
  }
}
.utilityIcons {
  display: none;
  background: #1f1f1f;
  position: absolute;
  width: 100%;
  height: 100%;
}
.utilityIcons div,
.utilityIcons a {
  position: absolute;
  cursor: pointer;
}
.utilityIcons div:before,
.utilityIcons a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 42px;
  background: #ebe8de;
  border: 1px solid #3333333d;
  z-index: 1;
}
.utilityIcons div:hover:before,
.utilityIcons a:hover:before {
  opacity: 0.8;
}
.utilityIcons .loadingIcon {
  color: red !important;
  animation: loader 2s linear infinite;
  top: 10px !important;
  right: -33px !important;
}
.utilityIcons .linkIcon {
  top: 8px;
  right: 100px;
}
.utilityIcons .linkIcon svg {
  position: absolute;
  width: 20px;
  top: 9px;
  right: -31px;
  color: #85858a;
  cursor: pointer;
  z-index: 11;
}
.utilityIcons .closeIcon {
  top: 8px;
  right: 50px;
}
.utilityIcons .closeIcon svg {
  position: absolute;
  width: 20px;
  top: 9px;
  right: -31px;
  color: #85858a;
  cursor: pointer;
  z-index: 11;
}
.utilityIcons .zoomInIcon {
  top: 8px;
  right: 200px;
}
.utilityIcons .zoomInIcon svg {
  position: absolute;
  width: 20px;
  top: 9px;
  right: -31px;
  color: #85858a;
  cursor: pointer;
  z-index: 11;
}
.utilityIcons .zoomOutIcon {
  top: 8px;
  right: 250px;
}
.utilityIcons .zoomOutIcon svg {
  position: absolute;
  width: 20px;
  top: 9px;
  right: -31px;
  color: #85858a;
  cursor: pointer;
  z-index: 11;
}
.utilityIcons .redoIcon {
  bottom: 50px;
  left: 10px;
}
.utilityIcons .redoIcon svg {
  position: absolute;
  width: 20px;
  top: 9px;
  right: -31px;
  color: #85858a;
  cursor: pointer;
  z-index: 11;
}
.utilityIcons .undoIcon {
  bottom: 50px;
  left: 60px;
}
.utilityIcons .undoIcon svg {
  position: absolute;
  width: 20px;
  top: 9px;
  right: -31px;
  color: #85858a;
  cursor: pointer;
  z-index: 11;
}
.utilityIcons .saveRotate {
  bottom: 45px;
  left: 110px;
  width: inherit;
}
.utilityIcons .saveRotate::before {
  width: fit-content;
}
.utilityIcons .FixDwIcon {
  top: 8px;
  right: 150px;
}
.utilityIcons .FixDwIcon svg {
  top: 9px !important;
  width: 20px !important;
  right: -33px !important;
}
.utilityIcons .downloadIcon {
  top: 8px;
  right: 150px;
}
.utilityIcons .downloadIcon svg {
  position: absolute;
  top: 12px;
  right: -30px;
  color: #85858a !important;
  fill: #85858a !important;
  cursor: pointer;
  z-index: 11;
}
.posFade {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 90%;
  max-height: 90%;
  min-height: 0;
  min-width: 0;
}
.posFade img {
  display: inherit;
}
.posFade > div:nth-child(2) {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  height: 100% !important;
}
.posFade > div:nth-child(2) > div {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  height: 100% !important;
  transform: none !important;
}
.defaultFade {
  position: relative;
  min-height: 0;
  min-width: 0;
  background-color: #1f1f1f;
  border-radius: 4px;
}
.defaultFade img {
  width: 100%;
  max-width: calc(100vw - 100px) !important;
  max-height: calc(100vh - 200px) !important;
}
.arrowsPreview__prev {
  left: -40px;
}
@media (max-width: 900px) {
  .arrowsPreview__prev {
    left: 20px;
  }
}
.arrowsPreview__next {
  right: -40px;
}
@media (max-width: 900px) {
  .arrowsPreview__next {
    right: 20px;
  }
}
.metaItem {
  margin-bottom: 5px;
}
.metaItem div:nth-child(2) {
  color: #838383;
}
@-webkit-keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.date {
  width: 100%;
  position: relative;
  text-align: left;
}
.date .dateLabelOpen {
  transform: translate(0, 3px) scale(0.75);
  top: 0;
  left: 0;
}
.dateSingle {
  width: 100%;
  position: relative;
}
.dateSingle .dateLabelOpen {
  transform: translate(5px, 3px) scale(0.75);
  top: 0;
}
.dateLabel {
  margin-left: 13px;
  transform-origin: top left;
  pointer-events: none;
  cursor: text;
  color: #6f7981;
  font-size: 16px;
  position: absolute;
  z-index: 1;
  font-weight: 400;
  transition: all 0.3s, color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  top: 12px;
  left: 0;
}
.dateLabel .required {
  color: red;
}
.dateLabelOpen {
  transform: translateY(-4px) scale(0.6);
  top: 5px;
  left: 0;
}
.dateForDeployment {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #efefef !important;
  background: white;
  padding-left: 10px;
  white-space: nowrap;
  text-align: left;
}
.dateForDeployment * {
  border: 0 none !important;
}
.dateForDeployment svg {
  display: none !important;
}
.link {
  color: #4f95e8;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  border-bottom: dashed 1px #4f95e8;
  text-decoration: none;
  display: inline;
}
.link:hover {
  color: #fa820f;
  border-bottom: dashed 1px #fa820f;
}
.link a {
  text-decoration: none;
}
.poper > div {
  padding: 0;
}
.infoIcon {
  margin-left: 10px;
}
.infoIcon:hover g circle:first-child {
  fill: #ffaa14 !important;
}
.infoIcon:hover g path {
  fill: #ffffff !important;
}
.infoIcon:hover g circle {
  fill: #ffffff;
}
.file {
  background-color: #fafafa;
  position: relative;
  cursor: pointer;
  display: block;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  max-width: 270px;
  height: 160px;
}
.file:hover::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background: rgba(74, 74, 74, 0.16) url("components/UI/Icons/Inputs/zoom.svg") no-repeat center center;
}
.fileMeta {
  position: absolute;
  z-index: 10;
  bottom: 15px;
  left: 5px;
}
.btn {
  transition: transform 0.3s, background-color 0.3s, border 0.3s;
  height: 45px;
  width: 160px;
  border-radius: 4px;
  border: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4f95e8;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
}
.btn:active {
  transform: scale(0.9);
}
.btn:hover {
  border: solid 1px #4f95e8 !important;
}
.btnDiabled {
  height: 45px;
  width: 160px;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  background: #dbdbdb;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
}
.loading {
  animation: rotating 2s linear infinite;
  fill: #4f95e8;
}
.link {
  color: #4f95e8;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  border-bottom: dashed 1px #4f95e8;
  text-decoration: none;
}
.link a {
  text-decoration: none;
}
.error {
  font-size: 13px;
  color: #e74c3c;
}
.required {
  color: red !important;
}
.checkBoxWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.formGroupRootSwitch {
  margin-right: 0 !important;
}
.checkBoxRWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.iphoneButtonsWrapper {
  margin: 5px 0 !important;
  display: flex;
  flex-direction: column;
}
.iphoneButtonsWrapper .iphoneButtonWrapper {
  display: flex;
  justify-content: space-between;
  margin: 5px 0 5px 34px;
  align-items: center;
}
.iphoneButtonsWrapper .iphoneButtonWrapper .iphoneButtonBugged {
  color: red !important;
}
.checkbox {
  cursor: pointer;
  font-size: 15px;
  color: #262626;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}
.checkbox svg {
  flex: 0 0 22px;
}
.checkbox:disabled svg {
  flex: 0 0 22px;
}
.checkbox:disabled svg g rect {
  fill: #e2e2e2 !important;
}
.checkbox:disabled:hover .checkFalse rect:last-child {
  stroke: #e3e3e3;
}
.checkbox svg {
  margin-right: 11px;
}
.checkbox .checkFalse rect:last-child {
  stroke: #e3e3e3;
}
.checkbox:hover .checkFalse rect:last-child {
  stroke: #ffaa14;
}
.checkBoxInput {
  width: 1px;
  height: 1px;
  opacity: 0;
}
.dateRoot input[type="date"]::-webkit-inner-spin-button,
.dateRoot input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.pos_preview_files {
  display: flex;
}
.pos_pin_files {
  margin: 15px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
@media (max-width: 900px) {
  .pos_pin_files {
    width: 100%;
  }
}
.btnCollectionWrapper {
  width: 100%;
}
@media (max-width: 900px) {
  .btnCollectionWrapper {
    width: 100%;
  }
}
.uploadImagePosButtonWrapper {
  cursor: pointer;
  position: relative;
  background-color: #fa820f;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.uploadImagePosButtonWrapper:hover {
  background-color: #fd9b3c;
}
.uploadImagePosButtonLabel {
  cursor: pointer;
  position: relative;
  width: 100%;
  display: flex;
  padding: 12px 0;
  justify-content: center;
  align-items: center;
}
.pos_pin_file {
  cursor: pointer;
  width: 240px;
  min-height: 160px;
  border: 1px dashed #c5c5c5;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #c5c5c5;
  transition: all 0.25s ease;
}
.pos_pin_file:hover {
  border: 1px dashed #acacac;
  color: #acacac;
}
.pos_pin_file:hover svg {
  fill: #b8b8b8 !important;
}
.pos_pin_file label {
  cursor: pointer;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pos_pin_file .pos_pin_file_plus svg {
  height: 46px;
  width: 46px;
  fill: #d2d2d2;
}
.pos_pin_file .pos_pin_file_text {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  padding: 0 20px;
}
.downloadWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: fit-content;
  margin-right: 15px;
  margin-bottom: 15px;
  width: 242px;
  height: 203px;
}
.fileChat {
  display: inline-block;
  width: 24px;
  height: 24px;
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal .MuiBackdrop-root {
  background-color: #5b6770;
  opacity: 0.4 !important;
}
.filename {
  word-break: break-word;
}
.download {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 22px;
  margin-bottom: 20px;
}
.download a {
  line-height: 12px;
}
.download svg {
  cursor: pointer;
}
.download svg path {
  fill: #c2c5c8;
}
.download svg:hover path {
  fill: #35393c;
}
.selectDisabled .selectInput {
  background: #f7f7f7 !important;
}
.selectRoot {
  cursor: pointer;
  position: relative;
  height: 48px;
}
.selectRoot .select {
  border: 0;
  height: 1px;
  width: 1px;
  background: transparent;
  opacity: 0;
}
.selectRoot .select option {
  width: 0;
  height: 0;
}
.selectRoot .selectInput {
  overflow: hidden;
  font-size: 15px;
  color: #2b2b2b;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #efefef;
  background-color: #ffffff;
  padding: 20px 0 10px 20px;
  position: relative;
}
.selectRoot .selectInput .required {
  color: red;
}
.selectRoot .selectInput .label {
  color: #6f7981;
  padding: 0;
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  left: 20px;
  top: 16px;
  position: absolute;
}
.selectRoot .selectInput .labelHasValue {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  left: 20px;
  top: 8px;
  position: absolute;
}
.selectRoot .selectInput .value {
  font-size: 15px;
  color: #363839;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  height: 28px;
  text-align: left;
}
.selectRoot .selectInput span {
  margin-right: 10px;
}
.selectRoot .selectInput span svg path {
  color: #707070;
  fill: #707070;
}
.selectRoot .selectInputWithShadow {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  border-radius: 4px 4px 0 0;
}
.options {
  transition: all 0.1s ease;
  background: white !important;
  color: #6f7981;
  font-size: 15px;
  font-weight: 400;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 4px 4px;
}
.options .item {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.options .item:hover {
  color: #fa6900;
}
.options .itemDisabled {
  color: #6f7981 !important;
}
.options .itemDisabled:hover {
  color: #6f7981 !important;
}
.options .item:first-child {
  padding: 10px 20px 5px 20px;
}
.options .item:last-child {
  padding: 5px 20px 10px 20px;
}
.options .iconUp {
  fill: #56be81;
}
.options .iconDown {
  fill: #e74c3c;
}
.optionsWrap {
  transition: all 0.1s ease;
  background: white !important;
  color: #6f7981;
  font-size: 15px;
  font-weight: 400;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 4px 4px;
  padding: 0;
  overflow-y: scroll;
  min-height: 150px;
  max-height: 150px;
}
.optionsWrap .item {
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.optionsWrap .item:hover {
  color: #fa6900;
}
.optionsWrap .itemDisabled {
  color: #6f7981 !important;
  opacity: 0.3;
}
.optionsWrap .itemDisabled:hover {
  color: #6f7981 !important;
}
.optionsWrap .item:first-child {
  padding: 10px 20px 5px 20px;
}
.optionsWrap .item:last-child {
  padding: 5px 20px 10px 20px;
}
.optionsWrap .iconUp {
  fill: #56be81;
}
.optionsWrap .iconDown {
  fill: #e74c3c;
}
.dialogWrapper {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  height: 175px;
}
.btnWrapper {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.btnWrapper button:first-child {
  margin-right: 15px;
}
.selectForDate {
  border: 0 none;
  text-transform: capitalize;
  color: #4f95e8;
  font-size: 16px;
}
.selectForDate option {
  font-size: 14px;
  color: black;
}
.relative {
  position: relative;
}
.availabilityDropdown {
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  z-index: 1;
}
.availabilityDropdownBirthday {
  right: 40px;
}
.availabilityDropdownAvatar {
  right: 6px;
  top: 75%;
}
