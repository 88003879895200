.wrapper {
  min-height: 58px;
  padding: 5px 15px;
  min-width: 300px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: #ffffff;
  pointer-events: auto;
  transition: transform 0.3s, background-color 0.3s, border 0.3s;
  transform: scale(0.1);
}
@media print {
  .wrapper {
    display: none;
  }
}
.wrapper div {
  height: 21px;
  display: flex;
  align-items: center;
}
.wrapper .icon {
  margin-right: 10px;
}
.wrapper .text {
  margin-right: auto;
  color: #35393c;
  font-size: 16px;
  font-weight: 500;
}
.wrapper .closeBtn {
  cursor: pointer;
  margin-left: 10px;
}
.wrapper .closeBtn:hover {
  fill: #bababa;
}
.success {
  border-left: solid 4px #27ae60;
}
.info {
  border-left: solid 4px #4f95e8;
}
.error {
  border-left: solid 4px #e74c3c;
}
.achievement {
  border-left: solid 4px #fffa00;
}
.warning {
  border-left: solid 4px #ffaa14;
}
.open {
  width: inherit;
  opacity: 1;
  transform: translateX(0);
}
.open .wrapper {
  opacity: 1;
  transform: translateX(0);
}
.open .text {
  width: 100%;
  opacity: 1;
  transform: translateX(0);
  transition: transform 0.5s 0.5s, opacity 1s 0.5s, width 0.5s 0.65s, color 0.5s 1.2s;
}
.link {
  cursor: pointer;
  color: #4f95e8;
  text-decoration: none;
}
.link:hover {
  color: #fa820f;
}
.externalWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.achievement {
  background: #fff;
  width: 100%;
}
.descriptionText {
  margin-right: auto;
  color: #35393c;
  font-size: 16px;
  font-weight: 300;
}
.flexContainer {
  height: auto !important;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
