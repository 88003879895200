.tabs {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: solid 1px rgba(112, 112, 112, 0.17);
  padding: 0 23px;
  width: 100%;
}
.tabsLabel {
  font-size: 15px;
  color: #09090a;
}
.tabsLabel .label {
  cursor: pointer;
  padding: 12px 0;
}
.tabsLabel:hover {
  color: #6f7981;
}
.selected {
  color: #6f7981;
  border-bottom: solid 4px #ffaa14;
  transition: border 0.3s ease-in-out, left 0.3s ease-in-out;
}
.selected:hover {
  color: #6f7981;
}
.total {
  margin: 0 30px 0 5px;
  border-radius: 50%;
}
.total .number {
  min-width: 2em;
  text-align: center;
  border-radius: 15px;
  font-size: 14px;
  line-height: 29px;
  padding: 4px 8px 3px;
  background: #e74c3c;
  color: #fff;
}
