.select {
  position: relative;
  border: 1px solid #efefef;
  background-color: #fff;
  border-radius: 4px;
}
.select .input {
  min-height: 48px;
  padding: 18px 30px 8px 16px;
  cursor: text;
  display: flex;
  align-items: center;
}
.select .label {
  position: absolute;
  left: 16px;
  top: 13px;
  font-size: 15px;
  color: #6f7981;
  transform-origin: left top;
  transition: all 0.3s;
  pointer-events: none;
}
.select .arrow {
  position: absolute;
  right: 16px;
  top: 11px;
  color: #6f7981;
}
.select .arrow svg {
  transform: rotate(180deg);
  transform-origin: center;
  transition: 0.3s;
}
.select .delete {
  position: absolute;
  right: 16px;
  top: 0;
  bottom: 0;
  color: #6f7981;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.1), #ffffff);
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 6px;
}
.select .delete line {
  transition: all 0.3s;
}
.select .delete:hover line,
.select .delete:focus line {
  stroke: red !important;
}
.select:hover,
.select:focus {
  border-color: #9fcafd;
}
.select.isDisabled {
  border: 1px solid #efefef !important;
  cursor: default !important;
  background-color: #f9f9f9;
}
.isFocused {
  border-color: #9fcafd;
}
.isFocused .arrow svg {
  transform: rotate(0deg) !important;
}
.isFilled .label {
  transform: translateY(-10px) scale(0.8);
}
.error {
  margin-bottom: 10px;
}
.item {
  margin-bottom: 10px;
}
