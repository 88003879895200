.mainWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.mainWrapper .main {
  flex: 1;
  display: flex;
}
.mainWrapper .side {
  width: 295px;
}
@media screen and (max-width: 1600px) {
  .mainWrapper .side {
    display: none;
  }
}
.title {
  padding-left: 13px;
  font-size: 23px;
  color: #383838;
  font-weight: 300;
}
.wrapper {
  border-radius: 4px;
  height: 100%;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.wrapper .main {
  flex: 1;
  align-items: stretch;
  flex-direction: column;
}
.wrapper .footer {
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 22px;
}
.title_wrap {
  padding: 15px 0 10px !important;
}
