.navigation {
  overflow: hidden;
  overflow-y: scroll;
  margin-right: -17px;
  height: calc(100vh - 64px);
  padding-top: 29px;
  padding-right: 28px;
  padding-bottom: 29px;
}
.navItemClose {
  padding: 9px 0 !important;
}
.navItemClose .navItemTitle {
  opacity: 0;
}
.navItemSelected {
  background: #eff0f1;
}
.navItem {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  width: 263px;
  transition: all 0.1s ease;
  padding: 9px 0 9px 0;
}
.navItem:hover {
  background: #eff0f1;
}
.navItem a {
  text-decoration: none;
}
.navItem .navItemIsOpen {
  margin-left: auto;
  height: 20px;
  display: flex;
  align-items: center;
  position: relative;
  top: 0;
  right: 10px;
}
.navItem .navItemIsOpen svg {
  color: #cccfd2;
  width: 15px;
}
.navItem .navItemIcon {
  width: 21px;
  height: 22px;
  margin: 0 10px 0 17px;
  color: #d1d4d7;
  fill: #d1d4d7;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navItem .navItemTitle {
  color: #5f676e;
  font-size: 16px;
  font-weight: 500;
}
.navItemTitleHover:hover {
  color: #fa820f;
}
.portalLink {
  margin-top: 100px;
  color: #6f7981;
  font-size: 15px;
  font-weight: 400;
  display: block;
  padding: 0 0 0 20px;
  text-decoration: none;
}
.subList {
  margin-top: -3px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  width: 263px;
}
.subList .subListItemActive {
  background: #eff0f1;
}
.subList .subListItem {
  color: #6f7981;
  font-size: 15px;
  font-weight: 400;
  display: block;
  padding: 10px 0 10px 50px;
  text-decoration: none;
}
.subList .subListItem:hover {
  background: #eff0f1;
}
