.dotsMenu {
  position: absolute;
  bottom: -83px;
  right: 15px;
  z-index: 9999;
  color: #6f7981;
  background: #ffffff;
  border-radius: 33px;
  height: 65px;
  width: 350px;
  display: flex;
  align-items: center;
}
.dotsMenu svg {
  height: 26px;
  width: 26px;
}
.menuItem {
  cursor: pointer;
  height: 65px;
  position: relative;
  min-width: 65px;
  justify-content: center;
  border-right: 1px solid #5b677018;
  display: flex;
  align-items: center;
}
.menuItem:last-child {
  border-right: 0;
  position: relative;
}
.menuItem:first-child {
  padding-left: 20px;
}
.menuItem:hover {
  background: #79828a30;
}
.menuItem__active {
  background: #79828a30;
}
.notifications_count {
  color: #fff;
  position: absolute;
  top: 14px;
  right: 8px;
  font-weight: bold;
  font-size: 12px;
  background-color: #ffaa14;
  opacity: 1;
  z-index: 9999;
  padding: 0 5px;
  display: flex;
  align-self: center;
  border-radius: 20px;
}
