.thumbHorizontal {
  cursor: pointer;
  border-radius: 3px;
  z-index: 12;
  background-color: rgba(0, 0, 0, 0.2);
  transform: translateX(0px);
}
.trackHorizontal {
  height: 100%;
  min-height: 100%;
  z-index: 9;
  top: 0;
  right: 2px;
}
.footer {
  height: 70px;
  padding: 0 24px;
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  flex-basis: auto;
  display: flex;
  align-items: center;
}
@media print {
  .footer {
    display: none;
  }
}
.appBarSpacer {
  min-height: 66px !important;
}
@media print {
  .appBarSpacer {
    display: none;
  }
}
.contentOpen {
  margin-left: 56px;
}
@media print {
  .contentOpen {
    display: none;
    margin-left: 0;
  }
}
