.toggle_notify_mobile {
  left: -126px !important;
}
.toggle_notify_mobile i {
  left: 152px !important;
  top: -5px !important;
}
.toggle_notify_ipad {
  left: -235px !important;
}
.toggle_notify_ipad i {
  left: 258px !important;
  top: -5px !important;
}
.toggle_notify {
  top: 120%;
  left: 0;
  width: 340px;
  height: 400px;
  position: absolute;
  z-index: 99;
  text-align: left;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-radius: 4px;
}
.toggle_notify li:last-child {
  padding-bottom: 60px;
}
.toggle_notify i {
  position: absolute;
  font-size: 15px;
  top: -7px;
  left: 25px;
  width: 12px;
  height: 24px;
  overflow: hidden;
}
.toggle_notify i:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 0;
  top: 10%;
  transform: rotate(45deg);
  background-color: #fff;
}
.toggle_notify ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}
.toggle_notify li {
  margin: 0;
  padding: 10px 15px 10px 15px;
  color: #6f7981;
  display: flex;
  font-size: 14px;
  cursor: auto;
  border-bottom: 1px solid #efefef;
}
.toggle_notify li:hover {
  background: #f8f8f8;
}
.toggle_notify li:hover svg {
  fill: #bfbfbf !important;
}
.toggle_notify li:first-child {
  padding-top: 15px;
}
.toggle_notify li:last-child {
  border-bottom: 0 !important;
}
.toggle_notify li .notify_date {
  font-size: 12px;
  color: #6f7981;
  padding-top: 3px;
}
.toggle_notify li p {
  padding: 0;
  margin: 0;
}
.toggle_notify li a {
  color: #4f95e8;
}
.tooltipBtn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
}
.notify_text {
  color: #2e2e2e;
}
.notify_deleteSvg {
  position: absolute;
  right: 5px;
}
.notify_deleteSvg:hover path {
  fill: #6f7981;
}
.notify_title {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 40px !important;
}
.notify_svg {
  margin-right: 15px;
  position: relative;
  top: 3px;
}
.link {
  color: #4f95e8;
}
.link:hover {
  color: #fa820f;
  text-decoration: none;
}
.readAll {
  position: absolute;
  background: white;
  text-align: center;
  border-top: 1px solid #efefef;
  border-radius: 0 0 4px 4px;
  height: 46px;
  line-height: 46px;
  bottom: 0;
  left: 0;
  right: 0;
  color: #4f95e8;
}
.readAll:hover {
  color: #fa820f;
}
.blackMode {
  background: #000 !important;
}
.blackMode:hover {
  background: #222 !important;
}
