.item {
  cursor: pointer;
  color: #6f7981;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 12px 8px 12px;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 4px;
}
.item:hover {
  background: #f1f3f6;
}
.item.checked {
  color: #2f3941;
  background: #f1f3f6;
}
.item.deleted {
  color: #aaa;
}
.item.deleted:hover {
  background: #f4f6f9;
}
