.wrap {
  background: white;
  position: fixed;
  z-index: 12;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.16);
}
.title {
  height: 74px;
  padding: 0 30px 0 75px;
  border-bottom: solid 1px #eff0f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title .titleRight {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.title .status {
  margin-right: 15px;
}
.title .hideWrap {
  cursor: pointer;
  opacity: 0.4;
  margin-right: 15px;
  display: flex;
  align-items: flex-end;
  height: 18px;
}
.title .hideWrap:hover {
  opacity: 1;
}
.title .hideWrap .hide line {
  fill: #676767;
  stroke: #676767;
}
.title .open {
  color: #676767;
  cursor: pointer;
  opacity: 0.4;
  margin-left: auto;
  margin-right: 15px;
}
.title .open:hover {
  opacity: 1;
}
.title .close {
  cursor: pointer;
  opacity: 0.4;
}
.title .close:hover {
  opacity: 1;
}
.main {
  transition: max-height 0.3s, padding 0.5s, opacity 0.3s;
  height: auto;
  opacity: 1;
}
.mainHidden {
  max-height: 0;
  opacity: 0;
}
.tabs {
  padding: 10px 30px 0 75px;
  border-bottom: solid 1px #eff0f1;
}
