.uploadedPhotosWrapper {
  margin-top: 25px;
  display: flex;
  width: 100%;
}
@media (max-width: 900px) {
  .uploadedPhotosWrapper {
    flex-wrap: wrap;
  }
}
.uploadedPhotosWrapper .uploadedPhotos__text {
  width: 228px;
  margin-right: 30px;
}
.uploadedPhotosWrapper .uploadedPhotos__text a {
  color: #4f95e8;
}
.uploadedPhotosWrapper .uploadedPhotos__text a:hover {
  color: #fa820f;
}
@media (max-width: 900px) {
  .uploadedPhotosWrapper .uploadedPhotos__text {
    width: 100%;
  }
}
.uploadedPhotosWrapper .uploadedPhotos {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
}
.uploadedPhotosWrapper .uploadedPhotos .uploadedPhotosItem {
  height: 170px;
  width: 24.5%;
  background-color: #f7f7f7;
}
.uploadingExamples {
  margin-bottom: 30px;
  margin-top: 25px;
  display: flex;
  width: 100%;
}
@media (max-width: 900px) {
  .uploadingExamples {
    flex-wrap: wrap;
  }
}
.uploadingExamples .uploadingExamples__text::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.uploadingExamples .uploadingExamples__text {
  width: 228px;
  margin-right: 30px;
  height: 250px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.uploadingExamples .uploadingExamples__text::-webkit-scrollbar {
  width: 0;
}
@media (max-width: 900px) {
  .uploadingExamples .uploadingExamples__text {
    height: auto;
  }
}
.uploadingExamples .uploadingExamples__text p {
  margin-top: 0;
  margin-bottom: 10px;
}
.uploadingExamples .uploadingExamples__text a {
  color: #4f95e8;
}
.uploadingExamples .uploadingExamples__text a:hover {
  color: #fa820f;
}
@media (max-width: 900px) {
  .uploadingExamples .uploadingExamples__text {
    width: 100%;
  }
}
.uploadingExamples .uploadingExamples__slider {
  min-height: 0;
  min-width: 0;
  flex: 1 0;
}
@media (max-width: 900px) {
  .uploadingExamples .uploadingExamples__slider {
    margin: 15px 0;
  }
}
.uploadingExamples .uploadingExamples__slider .uploadingExamples__slider_item {
  height: 135px;
  background-color: #f7f7f7;
  display: flex;
}
