.mentions {
  margin: 1em 0;
}
.ant-picker-footer {
  display: none;
}
.ant-picker {
  width: 100%;
}
input.ant-time-picker-input {
  border: 1px solid #efefef !important;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
}

.mentions--multiLine .mentions__control {
  font-family: monospace;
  font-size: 14pt;
}
.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  min-height: 63px;
}
.mentions--multiLine .mentions__input {
  border: 1px solid silver;
  padding: 9px;
  outline: 0;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  color: blue;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
  text-decoration: underline;
  pointer-events: none;
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
  z-index: 20;
  pointer-events: all;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.draftJsToolbar__buttonWrapper__1Dmqh {
  display: inline-block;
}

.draftJsLinkifyPlugin__link__2ittM {
  color: #4f95e8;
}

.draftJsLinkifyPlugin__link__2ittM:hover {
  color: #fa820f;
}

.draftJsToolbar__button__qi1gf {
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.draftJsToolbar__button__qi1gf svg {
  fill: #888;
}

.draftJsToolbar__button__qi1gf:hover,
.draftJsToolbar__button__qi1gf:focus {
  background: #f3f3f3;
  outline: 0; /* reset for :focus */
}

.draftJsToolbar__active__3qcpF {
  background: #efefef;
  color: #444;
}

.draftJsToolbar__active__3qcpF svg {
  fill: #444;
}
.draftJsToolbar__separator__3U7qt {
  display: inline-block;
  border-right: 1px solid #ddd;
  height: 24px;
  margin: 0 0.5em;
}
.draftJsToolbar__toolbar__dNtBH {
  border: 1px solid #efefef;
  background: #fbfbfbb8;
  box-shadow: 0px 1px 1px 0px rgba(220, 220, 220, 0.3);
  z-index: 2;
  box-sizing: border-box;
}

.draftJsToolbar__toolbar__dNtBH:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 4px;
  margin-left: -4px;
}
.draftJsToolbar__toolbar__dNtBH:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #ddd;
  border-width: 6px;
  margin-left: -6px;
}

.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  /*margin-bottom: 2em;*/
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #fefefe;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

.headlineButtonWrapper {
  display: inline-block;
}

.headlineButton {
  background: #fbfbfb;
  margin-top: 10px;
  color: #888;
  font-size: 18px;
  border: 0;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.headlineButton:hover,
.headlineButton:focus {
  background: #f3f3f3;
}

.DraftEditor-root {
  position: relative;
  margin-top: 20px;
  color: #6f7981;
}

.public-DraftEditorPlaceholder-root {
  position: absolute;
  color: rgba(0, 0, 0, 0.4);
}
