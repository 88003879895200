.StaffItem {
  width: 200px;
  height: 100%;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ebebeb;
  padding: 18px;
  position: relative;
}
.StaffItem:hover,
.StaffItem.DragActive {
  background: #f7f7f7;
}
.StaffLabel {
  margin-right: 20px;
  margin-bottom: 20px;
  width: 200px;
}
.StaffLabel > .StaffItem {
  width: 100%;
  margin-right: 0;
  margin-bottom: 0;
}
.dropdown {
  position: absolute;
  right: 10px;
  top: 10px;
}
.FileIcon {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  height: 114px;
}
.StaffItemCreate {
  border-style: dashed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
.Text {
  text-align: center;
  font-size: 13px;
  color: #6f7981;
  max-width: 145px;
}
.StaffTitle {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #35393c;
  font-size: 13px;
  font-weight: 500;
  margin: 0 0 6px;
}
.StaffDates,
.StaffLevel {
  color: #6f7981;
  font-size: 11px;
  line-height: 1;
  display: flex;
  align-items: center;
}
.StaffDates svg,
.StaffLevel svg {
  margin-right: 6px;
}
.StaffDates {
  margin: 0 0 8px;
}
.StaffLevel {
  margin: 0;
}
.StaffDealers {
  margin-top: 8px;
  margin-bottom: 0;
  color: #6f7981;
  font-size: 11px;
}
