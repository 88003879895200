.tableContainer {
  flex: 1 0 auto;
  background-color: #fff;
  border-radius: 4px 4px 0 0;
  min-height: calc(100vh - 307px);
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.not_found {
  color: #aeb4b8;
  font-size: 16px;
}
.table {
  min-height: 100%;
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
}
@media screen and (max-width: 575px) {
  .table {
    display: flex;
    flex-direction: column;
  }
}
.thead {
  display: table-header-group;
}
@media screen and (max-width: 575px) {
  .thead {
    display: none;
  }
}
.tbody {
  display: table-row-group;
}
@media screen and (max-width: 575px) {
  .tbody {
    display: flex;
    flex-direction: column;
  }
}
.tbody .tr {
  cursor: pointer;
  position: relative;
}
.tbody .tr:hover .td {
  background-color: #fbfbfc;
}
@media (hover: none) and (pointer: coarse) {
  .tbody .tr {
    cursor: inherit;
  }
  .tbody .tr:hover .td {
    background-color: #fff;
  }
}
.tbody .tr.withoutHover {
  cursor: inherit;
}
.tbody .tr.withoutHover:hover .td {
  background-color: #fff;
}
.tr {
  color: inherit;
  display: table-row;
  outline: 0;
  vertical-align: middle;
}
@media screen and (max-width: 575px) {
  .tr {
    display: flex;
    flex-direction: column;
    padding: 19px 16px;
    border-bottom: 1px solid #e8e8e8;
  }
}
.th {
  position: sticky;
  top: 66px;
  z-index: 5;
  background: white;
  background-clip: padding-box;
  display: table-cell;
  border-radius: 4px 4px 0 0;
  margin: 0;
  padding: 0;
  height: 100%;
  vertical-align: bottom;
}
.th div {
  height: 100%;
  color: #808080;
  line-height: 18px;
  padding: 16px;
  text-align: left;
  border-bottom: 2px solid #e8e8e8;
  font-weight: 400;
  font-size: 13px;
  text-transform: uppercase;
  white-space: nowrap;
}
.th .sort {
  color: #4f95e8;
  cursor: pointer;
  transition: all 0.1s !important;
}
.th .sort svg {
  transform: rotate(-180deg);
  transition: all 0.1s !important;
  color: #4f95e8;
  fill: #4f95e8;
  width: 16px;
  height: 16px;
  margin-bottom: -3px;
}
.th .activeSort,
.th .sort:hover {
  color: #fa820f;
}
.th .activeSort svg,
.th .sort:hover svg {
  color: #fa820f;
  fill: #fa820f;
}
.th .isRevert svg {
  transform: rotate(0deg);
}
.td {
  padding: 19px 16px;
  display: table-cell;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
}
@media screen and (max-width: 575px) {
  .td {
    display: block;
    padding: 0;
    border-bottom: 0;
  }
  .td > div {
    display: inline;
  }
}
.mobile_title {
  display: none;
}
@media screen and (max-width: 575px) {
  .mobile_title {
    display: inline;
    margin-right: 8px;
    font-weight: 600;
  }
}
.more {
  margin-top: 12px;
}
