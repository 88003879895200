.Tabs {
  display: flex;
}
.Tab {
  color: #6f7981;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 14px 24px;
  background: #e0e2e4;
  cursor: pointer;
  margin-right: 2px;
  border-radius: 4px 4px 0 0;
}
.ActiveTab {
  background: #fff;
}
