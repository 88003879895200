.container {
  display: flex;
  background: #f6f7f9;
  border-radius: 8px;
  padding: 20px;
  align-items: center;
}
.imgContainer {
  height: 100%;
}
.imgContainer > img {
  width: 100%;
}
.imgForNotif {
  width: 50px;
}
.info {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-left: 36px;
}
.info > p {
  margin: 0;
}
.info > p:first-child {
  font-weight: 700;
  font-size: 20px;
  color: #383838;
}
.info > p:last-child {
  font-weight: 400;
  font-size: 15px;
  color: #6f7981;
}
.infoForNotif {
  gap: 6px;
}
.countersContainer {
  display: flex;
  gap: 8px;
  margin-left: auto;
}
.counter {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
  background: #edeff2;
  width: 60px;
  height: 75px;
  border-radius: 8px;
}
.counter > svg {
  width: 28px;
  height: 28px;
}
.counter > span {
  font-weight: 500;
  font-size: 16px;
  color: #383838;
}
.inactive {
  opacity: 0.4;
}
.backgroundWhite {
  background: #fff;
}
