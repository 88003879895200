.ModalUpload {
  width: 464px;
}
.Title {
  font-weight: 300;
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 32px;
}
.Text {
  font-size: 15px;
  margin-top: -12px;
}
.Footer {
  justify-content: center;
  display: flex;
  gap: 12px;
  margin-bottom: -20px !important;
  margin-top: 32px;
}
.Form > div {
  margin-bottom: 16px;
}
.Form .Files {
  margin-bottom: 24px;
}
