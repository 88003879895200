.tableTitle {
  font-size: 16px;
  font-weight: 500;
  color: #5f676e;
  padding-left: 20px;
  padding-top: 20px;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #eff0f1;
}
.tableTitle span {
  color: rgba(0, 0, 0, 0.3);
  font-size: 16px;
  font-weight: 400;
  margin-left: 10px;
}
.table {
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
}
.table .tableHead {
  height: 45px;
  border-bottom: solid 2px #e8e8e8;
}
.table .tableHead .tableRow {
  height: 45px;
}
.table .tableHead .tableCell {
  font-size: 13px;
  text-transform: uppercase;
}
.table .tableBody .tableRow {
  height: 71px;
  border-bottom: solid 1px #e8e8e8;
}
.table .tableRow {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
}
.table .tableRow .tableCell {
  padding-top: 20px;
}
.sortHeaderItem {
  color: #4f95e8;
}
.sortHeaderItem:hover {
  color: #fa820f !important;
}
.tableWrapper {
  display: flex;
  flex-direction: column;
}
.wrapper {
  background: #ffffff;
  border-radius: 4px;
  height: 100%;
  min-height: 100%;
}
.wrapper td {
  font-size: 16px;
}
@media screen and (max-width: 1600px) {
  .wrapper td {
    font-size: 15px;
  }
}
.wrapper th::before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.main {
  width: 100%;
}
.mainStyles {
  width: 100%;
  height: calc(100% - 175px);
  min-height: calc(100% - 175px);
}
.overflowDisabledAutoHeight {
  min-height: 300px;
  border-radius: 4px;
}
.overflowAccounting {
  height: calc(100vh - 215px);
  min-height: 300px;
  border-radius: 4px;
}
.overflow {
  height: calc(100vh - 294px);
  min-height: 300px;
  border-radius: 4px;
}
.overflowChainRequests {
  min-height: 300px;
  border-radius: 4px;
  height: 300px;
}
.overflowStyles {
  height: calc(100vh - 383px);
  min-height: 350px;
  border-radius: 4px;
}
.overflow::-webkit-scrollbar-track,
.overflowStyles::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}
.overflow::-webkit-scrollbar,
.overflowStyles::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
.overflow::-webkit-scrollbar-thumb,
.overflowStyles::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d4d7da;
}
.pagination {
  border-top: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.pagination svg {
  margin-right: 5px;
}
.paginationStyle {
  width: 100%;
  height: 164px;
}
.paginationStyle .formCell {
  border-top: solid 1px #e8e8e8;
  width: 100%;
  padding: 20px 24px;
}
.paginationStyle .formCellMobile {
  border-top: solid 1px #e8e8e8;
  width: 100%;
  padding: 20px 24px 5px;
}
.opacity {
  background: black;
  opacity: 0.5;
}
.transparentTable th {
  background: rgba(0, 0, 0, 0.16);
  color: #5b6770 !important;
}
.transparentTable td {
  background: rgba(0, 0, 0, 0.16);
  color: #5b6770 !important;
}
.transparentWrapper {
  position: relative;
  z-index: 10;
}
.transparentWrapper::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #5b6770;
  opacity: 0.4;
  z-index: 10;
  left: 0;
  top: 0;
  bottom: 0;
}
.rowHover:hover {
  background-color: #fbfbfc !important;
  cursor: pointer;
}
.trackHorizontal {
  height: 100%;
  min-height: 100%;
  z-index: 50;
  top: 0;
  right: 2px;
}
.thumbHorizontal {
  cursor: pointer;
  border-radius: 3px;
  z-index: 12;
  background-color: rgba(0, 0, 0, 0.2);
  transform: translateX(0px);
}
