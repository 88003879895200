@media print {
  .audio audio {
    display: none;
  }
}
.video {
  width: 270px;
  max-height: 160px;
}
.video video {
  max-height: 110px;
  width: 270px;
}
