@-webkit-keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#SVG__loading {
  animation: rotating 2s linear infinite;
}
.button {
  transition: transform 0.3s, background-color 0.3s, border 0.3s;
  text-transform: uppercase;
  height: 48px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 23px;
  font-weight: 500;
}
.button span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button__regular {
  background-color: #fa820f;
  color: #fff;
}
.button__regular:hover {
  background-color: #fd9b3c;
}
.button__regular:hover div {
  color: #fff !important;
}
.button__add {
  background-color: #fa820f;
  color: #fff;
}
@media screen and (max-width: 1280px) {
  .button__add {
    width: 46px;
    height: 46px;
  }
  .button__add span {
    display: none;
  }
  .button__add::before {
    content: url("components/UI/Icons/Buttons/add.svg");
    display: block;
  }
}
.button__add:hover {
  background-color: #fd9b3c;
}
.button__regular:active {
  transform: scale(0.9);
  background-color: #e5770d;
}
.button__regular_query {
  width: 100%;
  font-size: 13px !important;
  height: 40px;
  background: #fa820f;
  font-weight: 400;
  color: #fff;
}
.button__regular_query:hover {
  background-color: #fd9b3c;
}
.button__regular_query:hover div {
  color: #fff !important;
}
.button__regular_query:active {
  transform: scale(0.9);
  background-color: #e5770d;
}
.button__filter {
  background-color: #ffaa14;
  color: #fff;
}
.button__filter:hover {
  background-color: #f2a112;
}
.button__filter:active {
  transform: scale(0.9);
  background-color: #f2a112;
}
.button__add {
  background-color: #fa820f;
  color: #fff;
}
.button__add:hover {
  background-color: #fd9b3c;
}
.button__add:active {
  transform: scale(0.9);
  background-color: #fd9b3c;
}
.button__submit {
  background-color: #00d289;
  color: #fff;
}
.button__submit:hover {
  background-color: #00dc91;
}
.button__submit:active {
  transform: scale(0.9);
}
.button__delete {
  color: #e74c3c;
  border: 1px solid #e3e3e3;
}
.button__delete:hover {
  border: 1px solid #e74c3c;
  color: #e74c3c;
}
.button__delete:active {
  transform: scale(0.9);
  color: #e74c3c;
}
.button__blue {
  color: #4f95e8;
  border: 1px solid #e3e3e3;
}
.button__blue:hover {
  border: 1px solid #4f95e8;
  color: #4f95e8;
}
.button__blue:active {
  transform: scale(0.9);
  color: #4f95e8;
}
.button__cancel {
  color: #414141;
  border: 1px solid #e3e3e3;
}
.button__cancel:hover {
  border: 1px solid #ffaa14;
}
.button__cancel:active {
  transform: scale(0.9);
}
.button__gray {
  color: #6f7981;
  border: 1px solid #e3e3e3;
  padding: 15px;
}
.button__gray:hover {
  background: #e3e3e3;
  border: 1px solid #e3e3e3;
}
.button__gray:active {
  transform: scale(0.9);
}
.button__vk {
  color: #fff;
  width: 100%;
  background: #4267b2;
  border: 0 none;
  padding: 15px;
}
.button__vk:hover {
  background: #4e73b2;
}
.button__vk:active {
  transform: scale(0.9);
}
.button__instagram {
  color: #fff;
  width: 100%;
  background: #414141;
  border: 0 none;
  padding: 15px;
}
.button__instagram:hover {
  background: #555555;
}
.button__instagram:active {
  transform: scale(0.9);
}
.button__loading {
  padding: 11px 25px;
  background-color: #00d289;
  fill: white;
  color: white;
}
.button__loading path {
  fill: white;
  color: white;
}
.button__loading svg {
  animation: rotating 2s linear infinite;
}
.button__loading:disabled {
  background-color: #00d289 !important;
}
.button__loading-done {
  background-color: #00d289;
}
.button__saved {
  background-color: #00d289;
  display: flex;
  align-items: center;
}
.button__saved svg {
  margin-right: 5px;
}
.button:disabled {
  background-color: #dbdbdb;
  color: #ffffff;
  border: none !important;
  cursor: default !important;
}
.button:disabled:hover,
.button:disabled:focus {
  background-color: #dbdbdb;
  border: none !important;
  transform: none;
}
.button__pollCompleted {
  background-color: #e3e3e3;
  font-size: 14px;
  text-align: left;
  color: #6f7981;
}
.wrapper {
  display: flex;
  align-items: center;
}
.tooltip_wrapper {
  position: relative;
}
.tooltip_wrapper span {
  color: #ffffff !important;
}
.tooltip_wrapper span svg {
  fill: #ffffff !important;
}
.button__regulation {
  transition: transform 0.3s, background-color 0.3s, border 0.3s;
  text-transform: uppercase;
  height: 48px;
  font-size: 15px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 23px;
  font-weight: 500;
  background-color: #fa820f;
  color: #fff;
}
.button__regulation:hover {
  background-color: #fd9b3c;
  color: #fff !important;
}
.button__regulation:hover span div {
  color: #fff !important;
}
.button__regulation:active {
  transform: scale(0.9);
  background-color: #e5770d;
}
.tooltip {
  width: 160px;
  position: absolute;
  z-index: 99;
  text-align: left;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-radius: 4px;
  color: #6f7981;
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
  padding: 20px;
}
.tooltip div {
  cursor: pointer;
  margin-bottom: 15px;
}
.tooltip div:last-child {
  margin-bottom: 0;
}
.tooltip div:hover {
  color: #fa820f;
}
.tooltip i {
  position: absolute;
  font-size: 15px;
  top: -6px;
  right: 6px;
  width: 12px;
  height: 24px;
  overflow: hidden;
}
.tooltip i:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 0;
  top: 10%;
  transform: rotate(45deg);
  background-color: #fff;
}
.button_regular_load:disabled {
  background: #f0a92c !important;
}
.fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: transparent;
}
.button__white {
  color: #414141 !important;
  background-color: #fff !important;
  border: 1px solid #e3e3e3 !important;
  transition: color 0.3s, transform 0.3s, border 0.3s !important;
}
.button__white span {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.button__white svg {
  width: 18px;
  margin-left: -6px;
  margin-right: -6px;
}
.button__white path {
  fill: #616161 !important;
  transition: all 0.3s;
}
.button__white:hover,
.button__white:active {
  color: #000 !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #d3d3d3 !important;
}
.button__white:hover path,
.button__white:active path {
  fill: #414141 !important;
}
.button__white:active {
  transform: scale(0.95);
}
.button__white:disabled {
  background-color: #fff !important;
  color: #414141 !important;
}
.button__white.button__loading {
  padding: 0 23px;
  border: 1px solid #e3e3e3 !important;
}
.button__white.button__loading svg {
  margin-left: -6px;
  margin-right: 6px;
}
.button__white.button__loading:disabled {
  border: 1px solid #e3e3e3 !important;
}
