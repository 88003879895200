/* breakpoints */
.container {
  position: relative;
}
.icon {
  display: flex;
  margin-left: 8px;
  transition: all 0.3s;
}
.dropdown {
  background: #fff;
  position: absolute;
  z-index: 99;
  width: 100%;
  top: 130%;
  left: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  transition: all 0.3s;
  transform: translateY(-5px) translateX(-50%);
  opacity: 0;
  min-width: 130px;
  pointer-events: none;
}
.dropdown .topArrow {
  position: absolute;
  width: 8px;
  height: 8px;
  left: 50%;
  top: 0;
  margin-top: -4px;
  transform: translateX(-50%) rotate(45deg);
  background-color: #fff;
}
.list {
  width: 100%;
  min-height: 30px;
}
.list div {
  padding: 8px 16px;
  color: #6f7981;
  cursor: pointer;
  line-height: 1.2;
}
.list div:hover {
  color: #ffaa14;
}
.list div:first-child {
  padding-top: 16px;
}
.list div:last-child {
  padding-bottom: 16px;
}
.list .disabled {
  color: #6f7981 !important;
  cursor: default;
  opacity: 0.6;
}
.isOpened .dropdown {
  pointer-events: inherit;
  opacity: 1;
  transform: translateY(0) translateX(-50%);
}
.isOpened .icon {
  transform: rotate(180deg);
}
.inlineGroup {
  display: flex;
}
.inlineGroup button {
  transition: all 0.3s !important;
  transform: scale(1) !important;
}
.inlineGroup button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.inlineGroup button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.clr-orange {
  border-color: #e3e3e3;
  background-color: #fff;
  color: #5b6770;
}
.clr-orange:hover {
  border-color: #fa820f;
}
.clr-orange.active {
  border-color: #fa820f;
  background-color: #fa820f;
  color: #fff;
  cursor: default;
}
.clr-gray {
  border-color: #e3e3e3;
  background-color: #fff;
  color: #5b6770;
}
.clr-gray:hover {
  border-color: #768187;
}
.clr-gray.active {
  border-color: #768187;
  background-color: #768187;
  color: #fff;
  cursor: default;
}
