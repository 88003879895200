.input-container {
  border: 1px solid #efefef;
  border-radius: 4px;
  width: 100%;
  display: flex;
  background: white;
  /* Для Firefox */
}
.input-container:hover,
.input-container:focus {
  border: 1px solid #9fcafd;
}
.input-container .label {
  width: 100%;
  height: 100%;
  padding: 15px 12px 8px 15px;
  position: relative;
  cursor: text;
  text-align: left;
}
.input-container .label .input {
  opacity: 0;
  outline: none;
  border: none;
  color: #191919;
  font-size: 15px;
  width: 100%;
}
.input-container .label .input:focus {
  opacity: 1;
}
.input-container .label .input:focus + .title {
  transition: 0.3s;
  transform: translate(0px, -8px);
  font-size: 11px;
}
.input-container .label .input::placeholder {
  color: #6f7981;
  opacity: 1;
  font-size: 16px;
  letter-spacing: 0.00938em;
}
.input-container .label .title {
  position: absolute;
  top: 10px;
  left: 16px;
  font-size: 16px;
  transition: 0.3s;
  color: #6f7981;
  transform-origin: left top;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  width: calc(100% - 32px);
  text-align: left;
}
.input-container input[type="number"]::-webkit-inner-spin-button,
.input-container input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-container input[type="number"] {
  -moz-appearance: textfield;
}
.notEmpty .label .input {
  opacity: 1;
}
.notEmpty .label .title {
  transform: translate(0px, -8px);
  font-size: 11px;
}
.focused {
  border: 1px solid #9fcafd;
}
.required {
  color: red;
}
.error {
  margin-bottom: 10px;
}
.disabled {
  background: #edf0f1;
  border: 1px solid #efefef !important;
}
.hidden {
  display: none;
}
