.subscribeFieldWrap {
  display: flex;
  flex-direction: column;
}
.settingsName {
  color: #6f7981;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.64px;
  font-size: 16px;
  margin-bottom: 24px;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
}
.flex > div {
  flex: 0 0 calc(50% - 10px);
}
.settings {
  position: absolute;
  right: 24px;
  top: 24px;
}
.settings > button {
  border-color: #f0a92c;
}
.settings > button svg > path {
  stroke: #f0a92c;
}
