.metaGetMore {
  color: #4f95e8;
  cursor: pointer;
}
.metaGetMore:hover {
  color: #fa820f;
}
.wrapperPos {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-radius: 4px;
  width: 400px;
  padding: 15px 20px;
}
.wrapperPos .item {
  margin: 12px 0;
}
.wrapperPos .title {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  color: #707070;
}
.wrapper {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-radius: 4px;
  width: 400px;
}
.wrapper .cost {
  padding: 15px 18px;
  color: #363636;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.wrapper .cost .total {
  font-size: 16px;
}
.wrapper .regulation {
  height: 100px;
  padding: 18px;
  border-radius: 0 0 4px 4px;
  background-color: #fff7ea;
}
.wrapper .regulation .title {
  color: #363636;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.wrapper .regulation .block {
  color: #6f7981;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.wrapper .regulation .block .price {
  margin-right: 5px;
}
.wrapper .regulation .block .price:last-child {
  margin-right: 0;
}
.wrapper .regulation .block .caption {
  color: #6f7981;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
}
.wrapper .regulation .block .item {
  color: #363636;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  white-space: nowrap;
}
