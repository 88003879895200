@import "_editorPlugins.css";

#filterForFormChipsWrapper {
  display: flex;
}

.TransformComponent-module_container__3NwNd {
  margin: 0 auto !important;
}

#dialogsWidget {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.DayPicker_transitionContainer__vertical {
  height: 400px !important;
}

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 100ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 100ms;
}

.iiz {
  margin: 0;
  position: relative;
  overflow: hidden;
  width: 100%;
  display: inline-block;
  cursor: zoom-in;
}
.iiz__img {
  max-width: 100%;
  height: auto;
  display: block;
  pointer-events: none;
}
.iiz__zoom-img {
  width: auto !important;
  max-width: none !important;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  display: block;
}
.iiz__zoom-img--visible {
  visibility: visible;
  opacity: 1;
  cursor: zoom-out;
}
.iiz__zoom-portal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
}
.iiz__btn {
  background: rgba(255, 255, 255, 0.8);
  width: 40px;
  height: 40px;
  border: none;
  outline: 0;
  padding: 0;
  position: absolute;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
}
.iiz__btn:before {
  content: " ";
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}
.iiz__hint {
  bottom: 10px;
  right: 10px;
  pointer-events: none;
}
.iiz__hint:before {
  content: " ";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.9 19.9'%3E%3Cpath d='M13.9 7.4C13.9 3.8 11 .9 7.4.9S.9 3.8.9 7.4s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5zm5.3 12.5l-6.7-7.2c-1.4 1.3-3.2 2.1-5.1 2.1-4.1 0-7.4-3.3-7.4-7.4S3.3 0 7.4 0s7.4 3.3 7.4 7.4c0 1.7-.6 3.4-1.7 4.7l6.8 7.2-.7.6z' fill='%23000222'/%3E%3C/svg%3E");
  width: 20px;
  height: 20px;
}
.iiz__close {
  top: 10px;
  right: 10px;
  visibility: hidden;
  opacity: 0;
}
.iiz__close--visible {
  visibility: visible;
  opacity: 1;
}
.iiz__close::before {
  content: " ";
  width: 29px;
  height: 29px;
  background-image: linear-gradient(#222, #222), linear-gradient(#222, #222);
  background-size: 100% 1px, 1px 100%;
  transform: rotate(45deg);
}

@-moz-document url-prefix() {
  * {
    outline: 0 !important;
  }
  :focus {
    outline: none;
  }
  ::-moz-focus-inner {
    border: 0;
  }
  table {
    border-collapse: unset !important;
  }
  .MuiTable-root {
    border-collapse: inherit !important;
  }

  table th {
    border-radius: 0 !important;
  }
}

.slick-dots {
  position: relative;
  margin-top: 20px;
  bottom: 0;
}

.slick-dots li {
  margin: 0 2px;
}

.slick-dots li button {
  padding: 0;
}

.slick-dots li.slick-active button:before {
  color: #b8bdc0;
}

.slick-dots li button:before {
  font-size: 13px;
  color: #dadee1;
  opacity: 1;
}

.sliderForTasks {
  width: 100%;
  height: 100%;
}

.sliderForTasks .slick-slide {
  align-self: stretch;
}

.sliderMediaPlanEdit {
  width: 100%;
  min-width: 0;
  min-height: 0;
}

.sliderForDealers {
  margin-bottom: 20px;
}

.sliderRedTaskCard,
.sliderRedTaskCardSep {
  width: 258px;
}

@media (max-width: 900px) {
  .sliderRedTaskCard {
    width: 100%;
  }
}

@media (max-width: 1280px) {
  .sliderRedTaskCardSep {
    width: 100%;
  }
}

.sliderPOSCardOuter .slick-dots {
  position: absolute;
  bottom: 15px;
}

.sliderPOSCardOuter .slick-arrow::before {
  content: none;
}

.sliderMediaPlanEdit .slick-arrow::before {
  content: none;
}

.sliderMediaPlanEdit .slick-arrow {
  transform: scale(0.8);
}

.sliderPOSCardOuter .slick-prev {
  background: url("./ArrowLeftSlider.svg") no-repeat center center !important;
  left: 5px;
  opacity: 1;
  z-index: 10;
}

.sliderPOSCardOuter .slick-next {
  background: url("./ArrowRightSlider.svg") no-repeat center center !important;
  right: 5px;
}

.sliderMediaPlanEdit .slick-prev {
  background: url("./ArrowLeftSlider.svg") no-repeat center center !important;
  left: -20px;
  top: 15px;
  opacity: 1;
  z-index: 10;
}

.sliderMediaPlanEdit .slick-next {
  background: url("./ArrowRightSlider.svg") no-repeat center center !important;
  right: -20px;
  top: 15px;
}

.sliderPOSCollection .slick-arrow {
  top: 35%;
}

.sliderForDealers .slick-list .slick-track,
.sliderForTasks .slick-list .slick-track,
.sliderRedTaskCard .slick-list .slick-track,
.sliderRedTaskCardSep .slick-list .slick-track,
.sliderMediaPlanEdit .slick-list .slick-track,
.sliderPOSCollection .slick-list .slick-track {
  display: flex;
}

.sliderMediaPlanEdit .slick-list .slick-track {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.sliderPOSCollection .slick-list .slick-track {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.sliderForDealers .slick-list .slick-track .slick-slide,
.sliderForTasks .slick-list .slick-track .slick-slide,
.sliderRedTaskCard .slick-list .slick-track .slick-slide,
.sliderRedTaskCardSep .slick-list .slick-track .slick-slide {
  outline: 0;
}

.sliderForDealers .slick-list .slick-track .slick-slide:not(:first-child),
.sliderRedTaskCard .slick-list .slick-track .slick-slide:not(:first-child),
.sliderForTasks .slick-list .slick-track .slick-slide:not(:first-child),
.sliderRedTaskCardSep .slick-list .slick-track .slick-slide:not(:first-child),
.sliderMediaPlanEdit .slick-list .slick-track .slick-slide:not(:first-child),
.sliderPOSCollection .slick-list .slick-track .slick-slide:not(:first-child) {
  margin-left: 10px !important;
}

.sliderPOSCollection .slick-arrow {
  top: 35%;
}

.sliderPOSCollection .slick-arrow::before {
  content: none;
}

.sliderPOSCollection .slick-prev {
  background: url("./ArrowLeftSlider.svg") no-repeat center center !important;
  left: -22px;
  opacity: 1;
  z-index: 10;
}

.sliderPOSCollection .slick-next {
  background: url("./ArrowRightSlider.svg") no-repeat center center !important;
  right: -22px;
}
