.avatar {
  border-radius: 50%;
  overflow: hidden;
  background: white;
  font-size: 15px;
  font-weight: 500;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .avatar {
    overflow: visible;
  }
}
