.textBlock {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.popup {
  color: #585858;
  font-size: 28px;
  font-weight: 300;
}
.caption {
  color: #585858;
  font-size: 23px;
  font-weight: 300;
}
@media (max-width: 500px) {
  .caption {
    font-size: 18px;
  }
}
.h1 {
  color: #585858;
  font-size: 23px;
  font-weight: 300;
  padding-left: 13px;
  line-height: 24px;
}
@media screen and (max-width: 900px) {
  .h1 {
    padding-left: 0;
  }
}
.h2 {
  color: #383838;
  font-size: 20px;
  font-weight: 300;
}
.h3 {
  color: #373a3c;
  font-size: 18px;
  font-weight: 500;
}
.h4 {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.h6 {
  font-size: 18px;
  font-weight: 500;
  color: #383838;
}
.regular {
  color: #35393c;
  font-size: 16px;
  font-weight: 400;
}
.table {
  color: #6f7981;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}
.title {
  position: relative;
}
.link {
  color: #4f95e8;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
}
.link:hover {
  color: #fa820f;
}
.link::after {
  content: "t";
  text-transform: lowercase;
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  left: 110%;
  top: -5px;
}
