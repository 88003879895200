.rightArrow {
  height: 42px;
  float: left;
  margin-right: 20px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
}
.rightArrow:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: -13px;
  width: 42px;
  height: 42px;
  border: 1px;
  border-radius: 50px;
  background: #f9f9f9;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s, visibility 0s linear 0.3s;
}
.rightArrow svg {
  color: #6f7981 !important;
  fill: #6f7981 !important;
}
.rightArrow:hover:before {
  opacity: 1;
}
