.headerIcon {
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  cursor: pointer;
}
@media print {
  .headPrint {
    display: none !important;
  }
}
.mobileIcon {
  position: relative;
  height: 66px;
}
.mobileIcon:hover {
  background: #79828a;
}
.mobileIcon::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background: #79828a;
  width: 1px;
  height: 66px;
}
.mobileIcon__active {
  background: #79828a;
}
.iconRoot {
  padding: 0;
}
.iconRoot:hover {
  background-color: transparent !important;
}
@media print {
  .iconRoot {
    display: none;
  }
}
.app_bar {
  background-color: #57636b !important;
  box-shadow: none !important;
}
.app_bar svg {
  fill: #fff;
}
@media print {
  .app_bar {
    display: none;
  }
}
