.editButtons {
  position: relative;
  display: flex;
  bottom: 55px;
  left: 17px;
}
.editButtons svg {
  position: relative;
  top: 2px;
}
.standart__form > * {
  margin: 0 !important;
  margin-bottom: 5px !important;
}
.dialogWrapper {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  height: 195px;
}
.dialogWrapper p {
  text-align: left;
  font-size: 17px;
}
.dialogWrapperConsent {
  width: 100%;
  gap: 10px;
}
.btnWrapper {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.btnWrapper button:first-child {
  margin-right: 15px;
}
.pos_new_form_wrapper {
  height: 64vh;
  padding: 20px;
}
.pos_new_buttonsWrapper .pos_new_buttonsWrapper__topline {
  position: relative;
  left: -20%;
  width: 120%;
  background-color: #eff0f1;
  height: 1px;
}
.pos_new_buttonsWrapper .pos_new_button_save {
  padding: 15px 0;
}
.textCenter div {
  justify-content: center;
  margin-bottom: 20px;
}
.toggleRolesWrap {
  display: flex;
  align-items: center;
}
.toggleRolesWrap .role {
  margin-left: 15px;
  color: #6f7981;
  border-bottom: 1px dashed #6f7981;
  cursor: pointer;
}
.toggleRolesWrap .role.toggled {
  color: rgba(111, 121, 129, 0.5) !important;
  border-bottom: 1px dashed rgba(111, 121, 129, 0.5) !important;
}
@media (max-width: 900px) {
  .toggleRolesWrap {
    flex-direction: column;
    margin-top: 5px;
  }
  .toggleRolesWrap .role {
    margin-left: 0;
  }
}
.textCenterPayments div {
  flex-direction: column;
  justify-content: center;
}
.textCenterPayments .subtitle {
  font-size: 15px;
  text-align: center;
  margin-bottom: 20px;
}
.buttonGroup {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonGroup > div:first-child {
  margin-right: 15px;
}
.buttonGroupCheck {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 1;
  padding-bottom: 32px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonGroupCheck div:first-child {
  margin-right: 15px;
}
.btnClose {
  position: absolute;
  right: 0;
  top: -29px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.1s ease;
}
.btnClose:hover,
.btnClose:focus {
  opacity: 1;
}
.pos_pos_header {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}
.pos_pos_header .item_news_text {
  flex: 0 1 66%;
  padding-right: 20px;
}
@media (max-width: 900px) {
  .pos_pos_header .item_news_text {
    flex: 0 1 100%;
  }
}
.rolesBlockWrap {
  display: flex;
  width: 100%;
  align-items: center;
}
.requestHeader,
.requestPortal {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  padding: 22px 0 0 22px;
}
.requestHeader .itemFull,
.requestPortal .itemFull {
  flex: 0 1 100%;
  padding-right: 20px;
  margin-bottom: 10px;
  position: relative;
}
.requestHeader .item,
.requestPortal .item {
  flex: 0 1 33%;
  padding-right: 20px;
  margin-bottom: 15px;
}
@media (max-width: 900px) {
  .requestHeader .item,
  .requestPortal .item {
    flex: 0 1 100%;
  }
}
.requestHeader .item_news_title,
.requestPortal .item_news_title {
  flex: 0 1 33%;
  padding-right: 20px;
  margin-bottom: 15px;
}
@media (max-width: 900px) {
  .requestHeader .item_news_title,
  .requestPortal .item_news_title {
    flex: 0 1 100%;
  }
}
.requestHeader .itemBoolean,
.requestPortal .itemBoolean {
  flex: 0 1 33%;
  padding-right: 15px;
  height: 48px;
  display: flex;
  align-items: center;
}
.requestHeader .itemBoolean div:first-child,
.requestPortal .itemBoolean div:first-child,
.requestHeader .itemBoolean div:last-child,
.requestPortal .itemBoolean div:last-child {
  padding: 2px !important;
  height: auto !important;
}
@media (max-width: 900px) {
  .requestHeader .itemBoolean,
  .requestPortal .itemBoolean {
    flex: 0 1 100%;
  }
}
.requestPortal {
  padding: 22px 75px;
}
.requestPortal .bottomForm {
  padding-left: 75px;
}
.DealersAgencyForm {
  display: flex;
  align-items: flex-start;
}
@media (max-width: 900px) {
  .DealersAgencyForm {
    flex-direction: column;
  }
}
.DealersAgencyForm .item1 {
  width: auto;
  padding-right: 15px;
}
.DealersAgencyForm .item1 div[role="combobox"] > div {
  margin-bottom: 0 !important;
}
@media (max-width: 900px) {
  .DealersAgencyForm .item1 {
    width: 100%;
    padding-right: 0;
  }
}
.DealersAgencyForm .item2 {
  width: 350px;
  padding-right: 15px;
}
.DealersAgencyForm .item2 div[role="combobox"] > div {
  margin-bottom: 0 !important;
}
@media (max-width: 900px) {
  .DealersAgencyForm .item2 {
    width: 100%;
    padding-right: 0;
  }
}
.orderPhoto {
  order: 1;
}
@media screen and (max-width: 980px) {
  .orderPhoto {
    order: 0;
  }
}
.item_title {
  font-size: 13px;
  font-weight: 400;
  color: #6f7981;
  margin: 10px 0;
}
.orderContent {
  margin-bottom: 30px !important;
  order: 0;
}
@media screen and (max-width: 980px) {
  .orderContent {
    order: 1;
  }
}
.table tr td {
  height: 70px;
  padding: 0 16px 0 16px;
  border: 0 none;
}
.requestTabRoot,
.requestTabRootPortal {
  padding: 22px 0 80px 22px;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.requestTabRoot .requestTabSideLeft,
.requestTabRootPortal .requestTabSideLeft,
.requestTabRoot .requestTabSideRight,
.requestTabRootPortal .requestTabSideRight {
  flex: 1;
}
.requestTabRoot .item,
.requestTabRootPortal .item {
  padding-right: 20px;
  margin-bottom: 15px;
  flex: 1 50%;
}
@media screen and (max-width: 900px) {
  .requestTabRoot,
  .requestTabRootPortal {
    flex-direction: column;
  }
  .requestTabRoot .item,
  .requestTabRootPortal .item {
    width: 100%;
    flex: 1;
  }
}
.requestTabRootPortal {
  padding: 22px 75px 80px;
}
@media screen and (max-width: 900px) {
  .tableRow {
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px #efefef;
    padding: 10px 0;
  }
  .tableRow td {
    border: 0 none;
  }
}
.bottomForm {
  border-top: solid 1px #efefef;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 22px;
  flex-wrap: wrap;
}
.bottomFormPortal {
  border-top: solid 1px #efefef;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 75px;
}
.formWrapper {
  margin: 0;
}
.formWrapper .mb12 {
  margin-bottom: 12px;
}
.formWrapper .title {
  line-height: 22px;
  font-size: 15px !important;
  color: #6f7981 !important;
  margin: 0 !important;
  margin-bottom: 10px !important;
  font-weight: 400 !important;
}
.form {
  height: calc(100% - 49px);
  position: relative;
}
.btnWrap {
  background: white;
  padding: 15px 23px 15px 23px;
  border-top: solid 1px #eff0f1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0 0 4px 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.btnWrapPortal {
  background: white;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 75px;
  border-top: solid 1px #eff0f1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.mb15 {
  margin-bottom: 15px;
}
.text {
  pointer-events: none;
  -webkit-animation: blink1 1s linear infinite;
  animation: blink1 1s linear infinite;
}
@-webkit-keyframes blink1 {
  0% {
    color: #ff0000;
  }
  50% {
    color: rgba(255, 0, 0, 0);
  }
  100% {
    color: #ff0000;
  }
}
@keyframes blink1 {
  0% {
    color: #ff0000;
  }
  50% {
    color: rgba(255, 0, 0, 0);
  }
  100% {
    color: #ff0000;
  }
}
.separator {
  background-color: #eef0f1;
  padding-top: 24px;
}
.topPart {
  display: flex;
  gap: 8px;
}
@media screen and (max-width: 1080px) {
  .topPart {
    flex-direction: column;
  }
}
.formBG {
  background: #fff;
}
