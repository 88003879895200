.attachLink {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 15px;
}
.downloadLink {
  cursor: pointer;
  font-size: 15px;
  color: #575757;
  text-decoration: none;
}
.downloadLink:hover {
  color: #ffaa14;
}
.imageIcon {
  height: 20px;
  margin-right: 5px;
}
.fileIcon {
  height: 20px;
  margin-right: 2px;
}
.closeIcon {
  cursor: pointer;
  width: 10px;
  margin-left: 5px;
}
.closeIcon line {
  stroke: #5b6770;
  opacity: 0.38;
}
.closeIcon:hover line {
  stroke: #5b6770;
  opacity: 1;
}
