.container {
  background-color: #fff;
  width: 100%;
  padding: 15px;
  border-radius: 4px;
  margin-top: 15px;
}
.header {
  border-bottom: solid 1px #e3e3e3;
  margin: -15px -15px 15px;
  padding: 10px 15px;
}
.footer {
  border-top: solid 1px #efefef;
  margin: 15px -15px -15px;
  padding: 15px;
  display: flex;
}
