.newMessage_simple {
  position: relative;
  top: -8px;
  font-size: 18px !important;
}
.newMessage {
  display: block;
  width: 36px;
  top: 21px;
  left: 22px;
  height: 36px;
  position: relative;
  border-radius: 50%;
  background: #3fbad091;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(87, 99, 107, 0.4);
  animation: pulse 2s infinite;
}
.newMessage div {
  position: relative;
  top: -20px;
  font-size: 16px;
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(87, 99, 107, 0.4);
    box-shadow: 0 0 0 0 rgba(87, 99, 107, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(87, 99, 107, 0);
    box-shadow: 0 0 0 10px rgba(87, 99, 107, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(87, 99, 107, 0);
    box-shadow: 0 0 0 0 rgba(87, 99, 107, 0);
  }
}
.circle_newMessage_animation {
  animation: pulse 1s infinite;
}
.circle {
  cursor: pointer;
  pointer-events: auto;
  z-index: 10;
  right: 35px;
  bottom: 35px;
  height: 4rem;
  width: 4rem;
  text-align: center;
  line-height: 5rem;
  transition: all 500ms cubic-bezier(0.93, 0.1, 0.42, 0.91);
  background: #57636b;
  color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.circle svg {
  font-size: 24px;
  top: 0;
  position: relative;
}
@media print {
  .circle {
    display: none !important;
  }
}
.widget {
  position: absolute;
  background-color: white;
  cursor: pointer;
  z-index: 10;
  pointer-events: auto;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 0 3px #57636b4d;
}
.widget .widget__title {
  background: #57636b;
  color: #fff;
  padding: 10px 15px;
  border: 1px;
  border-radius: 5px 5px 0 0;
}
.widget .widget__openBox {
  height: 350px;
  min-height: 0;
}
.widget .widget__unreadCount {
  position: relative;
  left: 5px;
  font-weight: bold;
  font-size: 12px;
  background-color: #ffaa14;
  padding: 1px 5px;
  border-radius: 50px;
}
.widget .widget__openIcon {
  position: absolute;
  top: 8px;
  right: 15px;
  width: 18px;
}
.widget .widget__openInChat {
  position: absolute;
  top: 8px;
  right: 40px;
  width: 18px;
}
