.container {
  position: absolute;
  z-index: 11;
  background-color: #ffffff;
  top: 100%;
  margin-top: 5px;
  border: 1px solid #efefef;
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  color: #6f7981;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: 400px;
  left: -1px;
  right: -1px;
}
.header {
  border-bottom: 1px solid #efefef;
}
.header input {
  border: 0;
  height: 48px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  line-height: 1;
  font-size: 15px;
}
.header input::placeholder {
  opacity: 1;
  color: #999;
}
.body {
  padding: 6px 0;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 250px;
}
.footer {
  border-top: 1px solid #efefef;
  padding: 16px 12px 16px 12px;
  display: flex;
  justify-content: space-between;
}
.footer .description {
  font-size: 13px;
  color: #888;
}
.footer button {
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}
.empty {
  text-align: center;
  color: #889197;
}
