*,
*:after,
*:before {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  outline: 0 none;
}
input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
  -webkit-appearance: none !important;
}
body {
  padding: 0;
  margin: 0;
  background: #f4f4f4;
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  padding: 0;
  margin: 0;
}
h1 {
  color: #585858;
  font-size: 23px;
  font-weight: 300;
}
h2 {
  color: #383838;
  font-size: 20px;
  font-weight: 300;
}
h3 {
  color: #373a3c;
  font-size: 18px;
  font-weight: 500;
}
h4 {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
}
button {
  outline: none;
  background: transparent;
  border: 0;
  padding: 0;
}
@media print {
  *,
  *:before,
  *:after,
  *:first-letter,
  p:first-line,
  div:first-line,
  blockquote:first-line,
  li:first-line {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
  }
}
.removed_dealer {
  color: #aaa !important;
}
