.chip {
  margin-right: 2px;
  margin-bottom: 2px;
  background-color: #f2f5f6;
  border-radius: 2px;
  padding: 2px 20px 2px 8px;
  position: relative;
  height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chip span {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.chip:last-child {
  margin-bottom: 10px;
}
