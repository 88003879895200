/* breakpoints */
.btn {
  transition: transform 0.3s, background-color 0.3s, border 0.3s;
  text-transform: uppercase;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid transparent;
  max-width: 330px;
  line-height: 1;
  text-decoration: none;
}
.btn.onlyView {
  cursor: default;
}
.btn > div {
  display: flex;
  align-items: center;
  width: 100%;
}
.btn .txt {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-align: center;
  width: 100%;
}
.btn:active:not(.onlyView),
.btn.active {
  transform: scale(0.96);
}
.btn:disabled {
  transform: scale(1);
  cursor: default;
  background: #e3eaf1 !important;
  border-color: #e3eaf1 !important;
  color: #959ba0 !important;
  transition: all 0s;
}
.btn:disabled:active {
  transform: scale(1);
}
.btn:disabled svg {
  fill: #bcc7d2 !important;
}
.clr-green {
  border-color: #00d289;
  background-color: #00d289;
  color: #fff;
}
.clr-green:hover:not(.onlyView),
.clr-green.active {
  border-color: #00dc91;
  background-color: #00dc91;
  color: #fff;
}
.clr-orange {
  border-color: #fa820f;
  background-color: #fa820f;
  color: #fff;
}
.clr-orange:hover:not(.onlyView),
.clr-orange.active {
  color: #fff;
  border-color: #fd9b3c;
  background-color: #fd9b3c;
}
.clr-white {
  border-color: #e3e3e3;
  background-color: #fff;
  color: #414141;
}
.clr-white:hover:not(.onlyView),
.clr-white.active {
  border-color: #ffaa14;
  color: #414141;
}
.clr-blue {
  border-color: #4f95e8;
  background-color: #4f95e8;
  color: #fff;
}
.clr-blue:hover:not(.onlyView),
.clr-blue.active {
  border-color: #66a3ec;
  background-color: #66a3ec;
}
.clr-blue-outline {
  border-color: #e3e3e3;
  background-color: #fff;
  color: #4f95e8;
}
.clr-blue-outline:hover:not(.onlyView) {
  border-color: #66a3ec;
  background-color: #fff;
  color: #4f95e8;
}
.clr-red {
  border-color: #e74c3c;
  background-color: #e74c3c;
  color: #fff;
}
.clr-red:hover:not(.onlyView),
.clr-red.active {
  border-color: #ec6153;
  background-color: #ec6153;
}
.clr-red-outline {
  border-color: #e3e3e3;
  background-color: #fff;
  color: #414141;
}
.clr-red-outline:hover:not(.onlyView) {
  border-color: #e74c3c;
  background-color: #fff;
}
.clr-gray {
  border-color: #768187;
  background-color: #768187;
  color: #fff;
}
.clr-gray:hover:not(.onlyView),
.clr-gray.active {
  border-color: #a7adb1;
  background-color: #a7adb1;
}
.sz-default,
.sz-normal {
  padding-left: 23px;
  padding-right: 23px;
  height: 48px;
}
.sz-small {
  height: 40px;
  padding-left: 17px;
  padding-right: 17px;
  font-size: 13px;
}
.sz-extraSmall {
  height: 34px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 12px;
}
.stretched {
  width: 100%;
  max-width: inherit;
}
.stretched span {
  width: 100%;
}
.loader {
  border: 2px solid transparent;
  border-top-color: #959ba0;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  flex: 0 0 20px;
  animation: loading 1.5s infinite linear;
  margin-right: 8px;
  margin-left: -8px;
}
@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
