::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
}
::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
input[name="header_searchMobile"]::placeholder {
  color: #889197;
}
input[name="header_searchMobile"]::-ms-input-placeholder {
  color: #889197;
}
input[name="header_searchMobile"]:-ms-input-placeholder {
  color: #889197;
}
.formWrap {
  display: flex;
  flex-grow: 1;
}
.formWrapMobile {
  display: flex;
  background: #fff;
  position: absolute;
  top: 160px;
  width: 350px;
  height: 65px;
  justify-content: center;
  align-items: center;
  border-radius: 45px;
  right: 15px;
  padding: 0 15px;
}
.form {
  width: 100%;
  height: 46px;
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.26);
}
.formMobile {
  width: 100%;
  height: 42px;
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.26);
}
.active {
  border-bottom: 1px solid #ffffff;
}
.searchIconInactive {
  cursor: pointer;
  position: relative;
  top: -2px;
  left: 5px;
  height: 19px;
  width: 19px;
  outline: 0;
}
.searchIconInactive:hover {
  fill: #ffaa14 !important;
}
.searchIconActive {
  cursor: pointer;
  opacity: 0.5;
  outline: 0;
  position: relative;
  top: 1px;
}
.searchIconActive:hover {
  opacity: 0.8;
}
.selectIcon {
  top: calc(50% - 3px) !important;
  right: 8px !important;
}
.select {
  color: #fff;
  opacity: 0.68;
  z-index: 9;
  font-size: 15px !important;
}
.select:focus {
  background-color: transparent !important;
}
.searchBlockMobile {
  position: relative;
  padding: 5px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.searchBlockMobile::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 38px;
  right: 0;
  top: 2px;
  background: #bfbfbf;
}
.searchBlockMobile .header_searchMobile {
  width: 65%;
  flex-basis: 65%;
  background: none;
  border: 0;
  color: #333;
  opacity: 1;
  outline: 0;
  padding: 3px;
  font-size: 16px;
}
.searchBlockMobile .header_search_submitMobile {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.searchBlockMobile .header_search_submitMobile input {
  background: none;
  text-transform: uppercase;
  border: 0;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}
.searchBlockMobile .header_search_submitMobile input:hover {
  color: #ffaa14;
}
.searchBlockMobile .clearInputMobile {
  position: absolute;
  left: 2px;
  top: 1px;
  cursor: pointer;
}
.searchBlockMobile .clearInputMobile svg {
  height: 20px;
  width: 20px;
  opacity: 0.5;
}
.searchBlockMobile .clearInputMobile svg:hover {
  opacity: 1;
}
.header_searchMobileButton {
  cursor: pointer;
}
.header_searchMobileSvg {
  height: 22px;
  width: 22px;
  fill: #bfbfbf !important;
  margin-right: 10px;
}
.searchBlock {
  position: relative;
  padding: 5px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.searchBlock::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 25px;
  right: 0;
  top: 7px;
  background: #889197;
}
.searchBlock .header_search {
  width: 90%;
  flex-basis: 90%;
  background: none;
  border: 0;
  color: #ffffff;
  opacity: 1;
  margin-left: 15px;
  outline: 0;
  padding: 3px;
  font-size: 16px;
}
.searchBlock .header_search_submit {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.searchBlock .header_search_submit input {
  background: none;
  text-transform: uppercase;
  border: 0;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  outline: none;
}
.searchBlock .header_search_submit input:hover {
  color: #ffaa14;
}
.searchBlock .clearInput {
  position: absolute;
  left: -22px;
  top: -1px;
  cursor: pointer;
}
.searchBlock .clearInput svg {
  height: 20px;
  width: 20px;
  opacity: 0.5;
}
.searchBlock .clearInput svg:hover {
  opacity: 1;
}
.selectWrap {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 9;
  margin-left: 5px;
}
.selectWrap > div {
  background: none;
  border: none;
  color: #fff !important;
}
.selectWrapMobile {
  display: flex;
  align-items: center;
  z-index: 9;
  margin-left: 5px;
}
.selectWrapMobile select {
  background: none;
  border: none;
  font-size: 16px;
  color: #5f676e !important;
}
