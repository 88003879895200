.tooltip_wrapper {
  position: relative;
  height: 12px;
  width: 12px;
  cursor: pointer;
}
.tooltip_wrapper .plusIcon {
  height: 12px;
  width: 12px;
}
.tooltip_wrapper .MoreVertIcon {
  height: 16px;
  width: 16px;
}
.tooltip_wrapper svg {
  height: 12px;
  width: 12px;
  cursor: pointer;
  margin: 0 !important;
  stroke: #959da3;
}
.tooltip_wrapper svg:hover {
  stroke: #5b6770;
  opacity: 1;
}
.tooltip {
  width: 175px;
  position: absolute;
  text-align: left;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 4px;
  padding: 20px;
  z-index: 99;
}
.tooltip .item {
  font-size: 15px;
  cursor: pointer;
  color: #4f95e8;
  padding-bottom: 10px;
}
.tooltip .item:hover {
  color: #fa820f;
}
.tooltip .item:last-child {
  padding-bottom: 0;
}
.tooltip svg {
  width: 12px;
  height: 12px;
  margin: 0;
}
.tooltip svg path {
  fill: #9b9b9b;
  stroke: #959da3;
}
.tooltip i {
  position: absolute;
  font-size: 15px;
  top: -6px;
  left: 50px;
  width: 12px;
  height: 24px;
  overflow: hidden;
}
.tooltip i:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 0;
  top: 10%;
  transform: rotate(45deg);
  background-color: #fff;
}
