.ChatBtn {
  background: transparent;
  border: 1px solid #e0e0e0;
  padding: 11px 16px;
  color: #6f7981;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  border-radius: 4px;
  margin-right: -10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.ChatBtn svg {
  margin-right: 6px;
  width: 18px;
  height: 18px;
}
.ChatBtn:hover {
  background: #e5e5e5;
}
.ChatContainer {
  position: absolute;
  bottom: 6px;
  right: 0;
}
.ChatContainer.ChatOpened {
  position: fixed;
  bottom: 11px;
  right: 24px;
  z-index: 11111111111;
}
.ChatContainer.ChatOpened .ChatBtn {
  background: #e5e5e5;
}
