.clearIcon {
  position: relative;
  top: 3px;
  cursor: pointer;
  width: 20px;
}
.clearIcon:hover {
  color: #fa820f;
}
.wordWrap {
  word-wrap: break-word;
}
.chatWrapper {
  background: white;
  height: calc(100vh - 150px);
  margin-top: 15px;
  width: 100%;
  border-radius: 4px;
}
.chatWrapper .chatSide {
  border-right: solid 1px #f0f0f0;
  height: 100%;
}
.chatWrapper .chatHeader {
  padding: 20px 15px 20px 20px;
  height: 56px;
  border-bottom: solid 1px #f0f0f0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.chatWrapper .chatHeader .find {
  height: 16px;
  stroke: #9b9b9b;
  fill: #9b9b9b;
  width: 18px;
  transition: width 1s;
}
.chatWrapper .chatHeader svg {
  fill: #c3c3c5;
  margin-right: 10px;
}
.chatWrapper .chatHeader div:only-child {
  margin: 0;
}
.chatDate {
  text-align: center;
  position: relative;
  background: white;
}
.chatDate span {
  background: white;
  position: relative;
  z-index: 2;
  padding: 0 30px !important;
  letter-spacing: 0;
  color: #707070;
  font-size: 13px;
  font-weight: 500;
}
.chatDate:before {
  content: "";
  position: absolute;
  width: 100%;
  display: block;
  left: 0;
  right: 0;
  top: 50%;
  border-bottom: 1px solid #eff0f1;
}
.chatSendMessageWrapper {
  width: 100%;
  padding: 5px 22px 13px 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chatSendMessage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  padding: 14px;
  border: solid 1px #efefef;
  border-radius: 4px;
  color: #272727;
  font-size: 15px;
}
.chatSendMessage .attach {
  height: 25px;
  margin: 0 8px 0 0;
  display: flex;
  align-items: center;
}
.chatSendMessage .attach .attachIcon {
  height: 25px;
  width: 23px;
  margin: 0 8px 0 0;
}
.chatSendMessage .attach .attachIcon:hover svg path {
  fill: #5b6770;
  opacity: 1;
}
.chatSendMessage .attach svg {
  height: 25px;
  width: 23px;
  cursor: pointer;
}
.chatSendMessage .attach .attachLink,
.chatSendMessage .attach a {
  display: flex;
  align-items: center;
}
.chatSendMessage .attach .closeIcon {
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.chatSendMessage .attach .closeIcon line {
  fill: #5b6770;
  stroke: #5b6770;
  opacity: 0.38;
}
.chatSendMessage .attach .closeIcon:hover line {
  fill: #5b6770;
  stroke: #5b6770;
  opacity: 1;
}
.chatSendMessage .smile {
  display: block;
  height: 24px;
  width: 24px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}
.chatSendMessage .smile svg {
  height: 24px;
  width: 24px;
}
.chatSendMessage .smile:hover svg path {
  fill: #5b6770;
}
.sendMessageButton {
  cursor: pointer;
  height: 28px;
  margin-left: 13px;
}
.sendMessageButton:disabled circle {
  fill: #5b6770;
  opacity: 0.38;
}
.chatTable {
  height: 100%;
  position: relative;
}
.chatTable .item {
  padding: 0 20px;
  height: 53px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.chatTable .item:hover {
  background: #f8f8f8;
}
.chatTable .item .content {
  padding-left: 13px;
  flex: 1;
}
.chatTable .item .content .title {
  font-weight: 500;
  font-size: 14px;
  color: #383838;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chatTable .item .content .subTitle {
  font-size: 12px;
  color: #707070;
  opacity: 0.57;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chatTable .itemHeader {
  padding: 0 16px 8px;
}
.chatTable .itemSelect {
  background: #eff0f1;
}
.chatTable .countNewMsg {
  color: #ff9452;
  font-size: 13px;
  font-weight: 500;
}
.ChatWindow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  position: relative;
  height: 100%;
  min-height: 100%;
}
.ChatWindow .chatHeader {
  padding: 6px 0 7px 24px;
  height: 56px;
}
.messageMe {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
}
.messageMe .message_wrapper {
  align-self: flex-end !important;
  color: #272727;
  position: relative;
}
.messageOther {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.chatInner {
  padding-right: 30px;
}
.Messages {
  overflow-y: scroll;
  padding: 10px 24px;
  flex: 1;
  transition: padding 0.5s ease-out;
}
.Messages a {
  color: #4f95e8;
}
.Messages a:hover {
  color: #fa820f;
}
.Messages .fileWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
}
.Messages::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #fff;
}
.Messages::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.Messages::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
  background-color: #d4d7da;
}
.message_wrapper {
  padding: 15px;
  color: #6f7981;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 5px;
  max-width: 50%;
}
.message_wrapper_me {
  cursor: pointer;
  background: #f8f8f8;
}
.message_wrapper_other {
  cursor: pointer;
  background: #f0f8ff;
}
.notRead {
  position: absolute;
  background-color: #d4e0df;
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  left: -18px;
  top: 23px;
}
.none {
  display: none;
}
.messageMe .User--position,
.messageOther .User--position {
  font-weight: 500;
  color: #383838 !important;
  font-size: 14px;
  background: transparent !important;
}
.userTop {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 42px;
}
.userTop .tooltip {
  position: absolute;
  width: 265px;
  top: 110%;
  left: 30px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  z-index: 99;
}
.userTop .tooltip .wrap .item {
  height: 53px;
  cursor: pointer;
  margin: 0;
  padding-left: 16px;
  position: relative;
}
.userTop .tooltip .wrap .item .content {
  margin-left: 13px;
}
.userTop .tooltip .wrap .item .content .title {
  font-weight: 500;
  font-size: 14px;
  color: #383838;
}
.userTop .tooltip .wrap .item:hover {
  background: #f8f8f8;
}
.userTop .tooltip .wrap .close {
  height: 10px;
  width: 10px;
  position: absolute;
  right: 10px;
}
.userTop .tooltip .wrap .close line {
  fill: #c3c3c5;
  stroke: #c3c3c5;
}
.userTop .tooltip .wrap .close:hover line {
  stroke: #959da3;
  fill: #959da3;
}
.userTop .tooltip i {
  position: absolute;
  font-size: 15px;
  top: -6px;
  left: 20px;
  width: 12px;
  height: 24px;
  overflow: hidden;
}
.userTop .tooltip i:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 0;
  top: 10%;
  transform: rotate(45deg);
  background-color: #fff;
}
.userTop .tooltip .item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
}
.userTop .tooltip .item:last-child {
  margin-bottom: 0;
}
.userTop .tooltip .item .content {
  margin-left: 10px;
}
.userTop .tooltip .item .title {
  font-weight: bold;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
}
.userTop .tooltip .item .subTitle {
  font-size: 12px;
  color: #6f7981;
}
.UserInChat {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.UserInChat.Me {
  justify-content: flex-end;
}
.UserInChat.Me .User--position {
  background: cornflowerblue;
}
.UserInChat .User--info {
  padding: 0 5px;
}
.UserInChat .User--name {
  font-weight: 700;
}
.UserInChat .User--position {
  border-radius: 3px;
  color: black;
  padding: 0 12px;
  font-weight: 500;
  line-height: 16px;
}
.UserInChat .User--position .title {
  font-size: 16px;
  letter-spacing: 0;
  color: #525252;
  font-weight: 500;
}
.UserInChat .User--position .subTitle {
  opacity: 0.57;
}
.UserInChat .User--position span {
  font-weight: normal;
  color: #b3b3b3;
}
.UserInChat .User--time {
  margin-right: 5px;
  font-size: 12px;
  color: #707070;
}
.UserInChat .subTitle {
  font-size: 13px;
  color: #707070;
  font-weight: 400;
}
.User {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}
.User.Me {
  justify-content: flex-end;
}
.User.Me .User--position {
  background: cornflowerblue;
}
.User .User--info {
  padding: 0 5px;
}
.User .User--name {
  font-weight: 700;
}
.User .User--position {
  border-radius: 3px;
  color: black;
  padding: 0 5px;
  font-weight: 500;
}
.User .User--position .subTitle {
  opacity: 0.57;
}
.User .User--position span {
  font-weight: normal;
  color: #b3b3b3;
}
.User .User--time {
  color: #707070;
  margin-right: 5px;
  font-size: 12px;
}
.preview {
  min-height: 300px;
}
.sendForm {
  position: relative;
}
.sendForm .form_output {
  position: absolute;
  left: 0;
  top: 6px;
  z-index: 1;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}
.sendForm .inputWrap {
  width: 100%;
  margin-right: 15px;
}
.sendForm .sendInput {
  width: 100%;
  position: relative;
  padding: 0;
}
.sendForm .sendInput strong {
  background-color: transparent !important;
}
.sendForm > label {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.file {
  width: 110px;
  height: 135px;
  background-color: #fafafa;
  position: relative;
  border: 1px solid #ededed;
  cursor: pointer;
  overflow: hidden;
}
.file:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #4a4a4a;
  opacity: 0.09;
}
.btn {
  height: 45px;
  width: 160px;
  border-radius: 4px;
  border: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4f95e8;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
}
.btn:hover {
  border: solid 1px #4f95e8;
}
.link {
  color: #4f95e8;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  border-bottom: dashed 1px #4f95e8;
  text-decoration: none;
}
.link a {
  text-decoration: none;
}
.required {
  color: #f44336;
  font-size: 12px;
}
.overFlowChatTable {
  height: 100%;
  min-height: 100%;
  overflow-y: scroll;
}
.chatTableWrapper {
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  border-radius: 4px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.itemChatTableWrap {
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.itemChatTable {
  height: 100%;
  min-height: 100%;
}
.itemChatTable .wrap {
  height: 100%;
  position: relative;
}
.itemChatTableWrap {
  height: 100%;
  min-height: 100%;
}
.itemChatTableWrap .itemChatTableTitle {
  font-weight: 500;
  font-size: 14px;
  color: #5f676e;
  padding: 22px 20px 10px;
}
.chatTableWrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #fff;
}
.chatTableWrapper::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.chatTableWrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d4d7da;
}
.chatTableHeader {
  background: white;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}
.avatar {
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  border-radius: 50%;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.avatar img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.avatarSmall {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  font-size: 15px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.avatarSmall img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}
.messageNotFound {
  height: 100%;
  font-size: 16px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -20px;
  color: #bfbfbf;
}
.CreateChat {
  width: 420px;
  background: white;
}
.CreateChat .btnClose {
  top: -25px;
}
.CreateChat .title div {
  justify-content: center;
  text-align: center;
  margin-bottom: 30px;
}
.CreateChat .input {
  margin-bottom: 35px;
}
.CreateChat button:first-child {
  margin-right: 10px;
}
.btnGroup {
  display: flex;
  align-items: center;
  justify-content: center;
}
.addUserInChat {
  position: relative;
}
.addUserInChat .title div {
  justify-content: center;
  text-align: center;
  margin-bottom: 25px;
}
.addUserInChat .selectUser {
  margin-bottom: 13px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.addUserInChat .selectUser .selectUserWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.addUserInChat .selectUser .selectItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 9px 9px 0;
}
.addUserInChat .selectUser .selectItem .title {
  margin-left: 9px;
  font-size: 14px;
  font-weight: 500;
}
.addUserInChat .selectUser .selectItem svg {
  margin-left: 9px;
  height: 9px;
  width: 9px;
  cursor: pointer;
}
.addUserInChat .selectUser .selectItem svg line {
  stroke: #dcdcdc;
}
.addUserInChat .selectUser .selectItem svg:hover line {
  stroke: #707070;
}
.addUserInChat .contentWrapper {
  height: 325px;
  position: relative;
}
.addUserInChat .contentWrapper .item {
  cursor: pointer;
  padding-left: 30px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.addUserInChat .contentWrapper .item:first-child {
  padding-top: 7px;
}
.addUserInChat .contentWrapper .item:hover {
  background: #f8f8f8;
}
.addUserInChat .contentWrapper .item .caption {
  margin-left: 13px;
}
.addUserInChat .contentWrapper .item .caption .title {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  color: #383838;
  opacity: 1;
}
.addUserInChat .contentWrapper .item .caption .subTitle {
  text-align: left;
  font: Regular 13px/15px Roboto;
  letter-spacing: 0;
  color: #707070;
  opacity: 0.57;
}
.addUserInChat .btnGroup {
  margin-top: 17px;
  margin-bottom: 17px;
}
.addUserInChat .btnGroup button:first-child {
  margin-right: 10px;
}
.dialogAddUser {
  width: 440px;
  height: 552px;
}
.searchInput {
  font-size: 15px;
  margin: 0 !important;
  color: #9b9b9b;
}
.searchInput div {
  margin: 0;
  padding: 0;
}
.searchInput input {
  font-size: 15px;
  letter-spacing: 0;
  color: #9b9b9b;
  padding: 4px 0 0;
}
.btnClose {
  position: absolute;
  right: 0;
  top: -60px;
  cursor: pointer;
  transition: opacity 0.1s ease;
}
.btnClose svg line {
  fill: #ffffff;
  stroke: #ffffff;
  opacity: 0.5;
}
.btnClose:hover svg line,
.btnClose:focus svg line {
  opacity: 1 !important;
}
.filesWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0 13px 13px;
}
.avatarNotify {
  background-color: #16d916;
  position: absolute;
  width: 12px;
  height: 12px;
  bottom: 0;
  right: -2px;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: help;
}
.msgAnswer {
  font-size: 10px;
  color: #4f95e8;
  cursor: pointer;
}
.message {
  align-items: flex-start;
  justify-content: space-between;
}
.message .message_tools {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
}
.message .message_tools .edit {
  margin-right: 10px;
}
.message .message_tools svg {
  cursor: pointer;
  opacity: 0.6;
}
.message .message_tools svg:hover {
  opacity: 1;
}
