.rootCell {
  padding: 18px 16px !important;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  color: #4f95e8;
  font-size: 15px;
  font-weight: 400;
}
.pagination .cell {
  padding: 0 7px;
  cursor: pointer;
}
.pagination .cell:hover {
  color: #fa820f;
}
.pagination .cellSelect {
  padding: 0 7px;
  color: #707070;
}
.root {
  border-top: solid 1px #e8e8e8;
  padding: 5px;
}
.toolbar {
  width: 100%;
  padding: 0 0 0 15px !important;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.toolbar .MuiTablePagination-selectRoot {
  padding: 0;
}
.spacer,
caption {
  display: none;
}
.caption {
  display: none;
}
.selectRootMobile {
  order: 3;
  margin-left: 0 !important;
  margin-right: 0 !important;
  justify-content: flex-end;
}
.paginationInput {
  flex-grow: 1;
}
.toolbarMobile {
  height: 100% !important;
  width: 100%;
  flex-wrap: wrap;
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.toolbarMobile .MuiTablePagination-caption {
  display: none;
}
.head td {
  padding: 19px 16px !important;
}
.head th {
  font-weight: 400;
  font-size: 13px;
  text-transform: uppercase;
  position: sticky;
  top: 0;
  z-index: 5;
  background: white;
  background-clip: padding-box;
  color: #808080;
  line-height: 18px;
}
.headActive {
  font-size: 13px;
  text-transform: uppercase;
}
.headActive div {
  color: #fa820f !important;
}
.headActive svg {
  color: #fa820f !important;
}
.head .headActive svg {
  color: #fa820f !important;
}
.headSort {
  font-size: 13px;
  text-transform: uppercase;
  color: #4f95e8 !important;
}
.headSort svg {
  color: #4f95e8 !important;
}
.headSort:hover div {
  color: #fa820f !important;
}
.headSort:hover svg {
  color: #fa820f !important;
}
.activeSort {
  text-transform: uppercase;
  color: #4f95e8 !important;
}
.activeSort:hover {
  color: #fa820f !important;
}
.arrowHeader {
  width: 16px !important;
}
.head .current {
  color: #fa820f !important;
}
.head .current svg {
  color: #fa820f !important;
  fill: #fa820f !important;
  width: 16px !important;
}
.active {
  color: #4f95e8 !important;
}
.body,
.body tr,
.body td {
  vertical-align: top !important;
}
.footer td {
  position: sticky;
  bottom: 0;
  z-index: 10;
  border-top: 0 none;
}
.footer td::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.selectRoot {
  margin-right: 15px !important;
  margin-left: 10px !important;
  width: 89px !important;
  height: 33px !important;
}
.selectRoot svg {
  top: 5px !important;
  right: 7px !important;
}
.selectIcon {
  width: 16px !important;
  top: 5px !important;
  right: 8px !important;
}
.select {
  width: 49px !important;
  height: 33px !important;
  border-radius: 4px !important;
  border: 1px solid #d5d5d5 !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 20px !important;
  font-size: 14px !important;
  color: #292929 !important;
}
.select .MuiSelect-root {
  border-radius: 4px;
  border: 1px solid #d5d5d5;
}
.root {
  height: 74px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rootMobile {
  height: 130px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.transparentTable th {
  filter: brightness(60%);
  -webkit-filter: brightness(60%);
}
.transparentTable td {
  filter: brightness(60%);
  -webkit-filter: brightness(60%);
}
.rowHover:hover {
  background-color: #fbfbfc !important;
  cursor: pointer;
}
.rowHover td {
  border-bottom: solid 1px #e8e8e8;
}
.posFooter .root {
  border: 0 none;
}
.continutySection > div {
  display: flex;
}
.continutySection > div > div:first-child {
  flex: 1 0 auto;
}
.continutySection > div > div:first-child > div {
  border-radius: 4px 0 0 4px;
}
.continutySection > div > button {
  border-radius: 0 4px 4px 0;
  height: 50px;
  background-color: #fff;
  padding: 0 10px;
  margin-left: -1px;
}
.continutySection > div > button:not(:disabled):hover {
  z-index: 11;
  position: relative;
}
.mobileLeft {
  padding-top: 15px;
}
