.dialogWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialogWrapperConsent {
  width: 900px;
  height: 400px;
  background: white;
  border-radius: 6px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .dialogWrapperConsent {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}
.text {
  font-size: 12px;
}
@media screen and (max-width: 576px) {
  .text {
    max-height: 240px;
    overflow: scroll;
  }
}
